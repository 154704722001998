import React, { useCallback, useMemo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { DNABox, DNAIcon, DNAText, util } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import {
  MATCH_SLIDE_STATUS,
  usePublisherVersioningState,
} from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
import { useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';

const S = StyleSheet.create({
  recommendedConfirmationContainer: {
    backgroundColor: colors['color-warning-5'],
    borderColor: colors['color-warning-500'],
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 16,
    minHeight: 45,
    marginBottom: 16,
  },
  recommendedConfirmationAlert: {
    fontSize: 20,
    color: colors['color-warning-500'],
  },
  warningText: {
    color: colors['color-warning-500'],
  },
  hyperLinksContainer: {
    color: colors['color-warning-500'],
    marginLeft: 4,
  },
  hyperLink: {
    color: colors['color-warning-500'],
    textDecorationLine: 'underline',
  },
  touchableOpacity: {
    flexDirection: 'row',
    marginRight: 4,
  },
})

interface DuplicateWarningProps {
  pageId: string
}

const DuplicateWarning: React.FC<DuplicateWarningProps> = ({ pageId }) => {
  const { matchSlideStates } = usePublisherVersioningState();
  const { onScrollToIndexInSlideGroupTarget } = useMatchSlidesState();
  const { groupedTargetItems } = useSingleItemDnd();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })
  const currentState = useMemo(() => matchSlideStates[pageId], [matchSlideStates])

  // TODO maybe move this to useSlideGroupTargetsState for less repatitve code. mappedItem is being calculated in that provider already
  const mappedItem = useMemo(() => groupedTargetItems[pageId]?.[0]?.itemId || null, [groupedTargetItems, pageId])
  const showConfirmationMessage = currentState &&
    currentState.status === MATCH_SLIDE_STATUS.NEEDS_REVIEW &&
    currentState?.duplicateSlides?.length;
  const hyperLinks = useCallback(() => {
    if (!currentState?.duplicateSlides) return [];
    return currentState.duplicateSlides
      .map((pageId, index, duplicateSlides) => {
        const pageNum = Number(pageId.split('_').pop());
        return (
          <TouchableOpacity
            key={`DuplicateWarning-${pageId}`}
            onPress={() => onScrollToIndexInSlideGroupTarget(pageNum - 1)}
            style={S.touchableOpacity}
          >
            <DNAText style={S.hyperLink}>
              #{pageNum}
            </DNAText>
            {index + 1 < duplicateSlides.length && <DNAText style={S.warningText}>{', '}</DNAText>}
          </TouchableOpacity>
        );
      })
      .filter(Boolean);
  }, [currentState]);

  if (!showConfirmationMessage || !mappedItem) return null
  return (
    <DNABox
      fill
      alignX="start"
      alignY="center"
      style={util.mergeStyles(
        undefined,
        S.recommendedConfirmationContainer,
        { width: isLargeScreen ? 748 : 548 },
      )}
    >
      <DNABox alignY="center" spacing="sm">
        <DNAIcon
          name="alert-circle"
          status="warning"
          style={S.recommendedConfirmationAlert}
        />
        <DNAText status="warning">
          This slide is also matched to the following slide(s) in the new version:
        </DNAText>
      </DNABox>
      <DNABox fill>
        <DNAText numberOfLines={1} ellipsizeMode="tail" style={S.hyperLinksContainer}>
          {hyperLinks()}
        </DNAText>
      </DNABox>
    </DNABox>
  )
}

export default DuplicateWarning
