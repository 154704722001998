import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import { TabView, Tab } from '@ui-kitten/components';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';

// TAB CONTENT
import DocumentInfo from 'src/screens/Publishers/Versioning/DocumentInfo';
import MatchSlides from '../MatchSlides/MatchSlides';
import SlideSettings from '../SlideSettings/SlideSettings';
import DocumentAssociatedFiles from 'src/screens/Publishers/Versioning/DocumentAssociatedFiles/DocumentAssociatedFiles';
import DocumentPublish from '../DocumentPublish';
import DocumentCriticalError from '../VersioningCriticalError';
import { TabOptions } from 'src/state/machines/publisherVersioning/publisherVersioning.types';

export const S = StyleSheet.create({
  tabsContentWrapper: {
    borderTopColor: colors['color-gray-100'],
    borderTopWidth: 1,
    backgroundColor: colors['color-gray-10'],
    zIndex: -1,
  },
  tabTitleContainer: {
    marginVertical: 10,
  },
})

export interface TabConfig {
  testID: string,
  id: TabOptions,
  tabTitle: string,
  component: React.ElementType,
}

interface TabTitleProps {
  enableIcon?: boolean,
  text: string,
  idx: number,
  currentIdx: number,
}

const TabTitle: React.FC<TabTitleProps> = (props) => {
  const { enableIcon, idx, currentIdx, text } = props
  const isSelected = idx === currentIdx
  const status = isSelected ? 'primary' : 'subtle'

  return (
    <DNABox
      alignY="center"
      spacing="sm"
      style={S.tabTitleContainer}
    >
      <Iffy is={enableIcon}>
        <DNAIcon.Styled
          appearance="outline"
          name={`numeric-${idx + 1}-box`}
          size="lg"
          status={isSelected ? 'primary' : 'gray'}
        />
      </Iffy>
      <DNAText status={status}>{text}</DNAText>
    </DNABox>
  )
}

const Tabs: React.FC = () => {
  const {
    service,
    isCriticalError,
    setSelectedIndex,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.selectedTabIndex,
      publisherVersioningSelector.isNavBlocked,
      publisherVersioningSelector.isProcessingNewVersion,
      publisherVersioningSelector.isPublishing,
      publisherVersioningSelector.availabletabs,
    ),
  )

  const publisherVersioningTabs: Record<TabOptions, TabConfig> = useMemo(() => ({
    [TabOptions.DOCUMENT_INFO_TAB]: {
      testID: 'document-info-tab',
      id: TabOptions.DOCUMENT_INFO_TAB,
      tabTitle: 'Document Info',
      component: DocumentInfo,
    },
    [TabOptions.DOCUMENT_MATCH_SLIDES_TAB]: {
      testID: 'match-slides-tab',
      id: TabOptions.DOCUMENT_MATCH_SLIDES_TAB,
      tabTitle: `Match Slides${cond.isInDraftingState ? ' (optional)' : ''}`,
      component: MatchSlides,
    },
    [TabOptions.DOCUMENT_SETTINGS_TAB]: {
      testID: 'settings-tab',
      id: TabOptions.DOCUMENT_SETTINGS_TAB,
      tabTitle: `Slide Settings${cond.isInDraftingState ? ' (optional)' : ''}`,
      component: SlideSettings,
    },
    [TabOptions.DOCUMENT_ASSOCIATED_FILES]: {
      testID: 'massociated-files-tab',
      id: TabOptions.DOCUMENT_ASSOCIATED_FILES,
      tabTitle: `Associated Files${cond.isInDraftingState ? ' (optional)' : ''}`,
      component: DocumentAssociatedFiles,
    },
    [TabOptions.DOCUMENT_PUBLISH_TAB]: {
      testID: 'release-notes-tab',
      id: TabOptions.DOCUMENT_PUBLISH_TAB,
      tabTitle: !cond.isInDraftingState ? 'Release Notes' : 'Publish',
      component: DocumentPublish,
    },
  }), [cond.isInDraftingState])

  return (
    <DNABox fill style={S.tabsContentWrapper}>
      <Iffy is={!isCriticalError}>
        <TabView
          swipeEnabled={false}
          selectedIndex={cond.selectedTabIndex}
          onSelect={setSelectedIndex}
          style={{ flex: 1 }}
        >
          {
            cond.availabletabs.map((tabOption, index) => {
              const tabConfig = publisherVersioningTabs[tabOption]
              const TabContent = tabConfig.component
              return (
                <Tab
                  key={tabConfig.testID}
                  testID={tabConfig.testID}
                  disabled={cond.isNavBlocked}
                  title={() => (
                    <TabTitle
                      idx={index}
                      enableIcon={cond.isInDraftingState}
                      currentIdx={cond.selectedTabIndex}
                      text={tabConfig.tabTitle}
                    />
                  )}
                >
                  <DNABox fill>
                    <TabContent />
                  </DNABox>
                </Tab>
              )
            })
          }
        </TabView>
      </Iffy>
      {/* CRITICAL ERROR SCREEN */}
      <Iffy is={isCriticalError}>
        <DocumentCriticalError />
      </Iffy>
    </DNABox>
  )
}

export default Tabs
