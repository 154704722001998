import { CustomDeck, CustomDeckGroup as CustomDeckGroups, User } from '@alucio/aws-beacon-amplify/src/models'

import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import addDays from 'date-fns/addDays'
import isPast from 'date-fns/isPast'
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { CustomDeckORM, DocumentVersionORM, FolderItemORM, FolderORM, VERSION_UPDATE_STATUS } from 'src/types/types';
import * as logger from 'src/utils/logger'

import { commonReducers, datastoreSave, initialState, SliceState, SliceStatus } from './common'
import {
  lockCustomDeck as lockCustomDeckMutation,
  updateCustomDeckLambda,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { LockCustomDeckMutation, UpdateCustomDeckLambdaMutation } from '@alucio/aws-beacon-amplify/src/API';
import sharedFolderSlice from './sharedFolder'
import omit from 'lodash/omit';
import { DNAModalActions } from './DNAModal/DNAModal';
import RemoveErrorMessage from 'src/components/DNA/Modal/DNAPresentationBuilder/RemoveErrorMessage';
import { getRootFolderORM } from '../selector/folder';
import { EDITOR_TYPE } from './PresentationBuilder/PresentationBuilder';

type UpdateLambdaCustomDeckType = {
  customDeck: CustomDeck,
  groups: CustomDeckGroups[],
  title: string,
  folder: FolderORM,
  folderItem: FolderItemORM,
  currentUser: User,
}

const lockCustomDeck = createAsyncThunk(
  'sharedFolder/lockCustomDeck',
  async (args: { customDeckId: string, timestarted: string, lock: boolean, currentUser: User }) => {
    const { customDeckId, timestarted, lock, currentUser } = args

    const { data } = await API.graphql(graphqlOperation(lockCustomDeckMutation, {
      customDeckId: customDeckId,
      timestarted: timestarted,
      lock: lock,
    })) as GraphQLResult<LockCustomDeckMutation>;
    return {
      updatedObject: data?.lockCustomDeck || {},
      currentUser,
    }
  },
)

const updateCustomDeck = createAsyncThunk(
  'sharedFolder/updateCustomDeck',
  async ({
    customDeck,
    groups,
    title,
    folder,
    folderItem,
    currentUser,
  }: UpdateLambdaCustomDeckType, thunkAPI) => {
    const updateObj = {
      ...customDeck,
      groups,
      title,
      autoUpdateAcknowledgedAt: new Date().toISOString(),
    };

    const { dispatch } = thunkAPI

    //  Update the title on the front end
    dispatch(
      sharedFolderSlice.actions.updateFolderItemTitle({
        id: folderItem.model.id,
        title: title,
      }),
    );
    let updatedObject: (UpdateCustomDeckLambdaMutation | undefined)
    try {
      // We need to send the parent folder to this call to make the validations, due to the element that is being shared
      const rootFolder = getRootFolderORM(folder) || folder;
      const result = (await API.graphql(
        graphqlOperation(updateCustomDeckLambda, {
          customDeck: {
            ...omit(updateObj, ['_version', '_deleted', '_lastChangedAt']),
          },
          folderId: folder.model.id,
          rootFolderId: rootFolder.model.id,
        }),
      )) as GraphQLResult<UpdateCustomDeckLambdaMutation>;
      updatedObject = result.data
    } catch (e) {
      const errors = [
        'Custom deck is REMOVED',
        'Custom deck is not shared with user',
        'User does not have permissions to edit this deck',
        'Custom deck is NOT_SHARED',
      ];
      // [TODO] - Handle the typing properly
      if (errors.includes((e as any).errors[0].message)) {
        dispatch(
          DNAModalActions.setModal({
            isVisible: true,
            allowBackdropCancel: true,
            // @ts-expect-error // Check how to pass the message to the component without change to tsx
            component: RemoveErrorMessage,
          }),
        );

        dispatch(customDeckSlice.actions.remove(customDeck));
      }
    }

    analytics?.track('CUSTOM_SAVE', {
      action: 'SAVE',
      category: 'CUSTOM',
      customDeckId: customDeck.id,
      editorType: EDITOR_TYPE.COLLABORATOR,
    });

    // [TODO]: Check why typescript doesnt allow to call this thunk directly
    dispatch(
      (customDeckActions.lockCustomDeck({
        customDeckId: customDeck.id,
        lock: false,
        timestarted: '',
        currentUser,
      }) as unknown) as AnyAction,
    );

    return { updatedObject: updatedObject?.updateCustomDeckLambda, currentUser };
  },
);

const sliceName = 'customDeck';
const { reducers, extraReducers } = commonReducers<CustomDeck>(sliceName)

export const AUTO_UPDATE_DEFAULT_DATE = '1900-01-01T00:00:00.000Z'

export const customDeckSlice = createSlice({
  name: sliceName,
  initialState: initialState<CustomDeck>(),
  reducers: {
    ...reducers,
    acknowledgeAutoUpdate: {
      prepare: (customDeckORMs: CustomDeckORM[]) => {
        return {
          payload: {
            customDeckORMs,
          },
        }
      },
      reducer: (
        state: SliceState<CustomDeck>,
        action: PayloadAction<{
          customDeckORMs: CustomDeckORM[],
        }>,
      ) => {
        const now = new Date().toISOString();
        const { customDeckORMs } = action.payload;

        customDeckORMs.forEach(customDeckORM => {
          datastoreSave<CustomDeck>(CustomDeck, customDeckORM.model, {
            autoUpdateAcknowledgedAt: now,
            updatedAt: now,
          })

          // TODO if analytics is required, it should go here
        })
      },
    },
    updateDocumentVersion: {
      prepare: (
        customDeckORMs: CustomDeckORM[]) => {
        return {
          payload: {
            customDeckORMs,
          },
        }
      },
      reducer: (
        _state: SliceState<CustomDeck>,
        action: PayloadAction<{
          customDeckORMs: CustomDeckORM[],
        }>,
      ) => {
        const { customDeckORMs } = action.payload;
        const sharedCustomDeckORMs = customDeckORMs.filter((deck) => {
          return _state.records.some((record) => record.id === deck.model.id)
        })
        updateCustomDecks(sharedCustomDeckORMs)
      },
    },
    applyAutoUpdate: {
      prepare: (
        customDeckORMs: CustomDeckORM[],
        gracePeriodDays: number) => {
        return {
          payload: {
            customDeckORMs,
            gracePeriodDays,
          },
        }
      },
      reducer: (
        state: SliceState<CustomDeck>,
        action: PayloadAction<{
          customDeckORMs: CustomDeckORM[],
          gracePeriodDays: number
        }>,
      ) => {
        const { customDeckORMs, gracePeriodDays } = action.payload;
        const filteredCustomDeckORMs = customDeckORMs.filter((deck) => {
          return state.records.some((record) => record.id === deck.model.id)
        })
        // In this case we use the isAutoUpdate flag and the gracePeriodDays for validation
        updateCustomDecks(filteredCustomDeckORMs, true, gracePeriodDays)
      },
    },
  },
  extraReducers: {
    [lockCustomDeck.fulfilled.toString()]: (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      if (payload.updatedObject.createdBy !== payload.currentUser.id) {
        state.records = state.records.filter(customDeck => customDeck.id !== payload.updatedObject.id)
        state.records.push(payload.updatedObject)
      }
    },
    [lockCustomDeck.rejected.toString()]: (state) => {
      state.status = SliceStatus.ERROR;
    },
    [updateCustomDeck.fulfilled.toString()]: (state, { payload }) => {
      state.status = SliceStatus.OK;
      if (payload.updatedObject.createdBy !== payload.currentUser.id) {
        state.records = state.records.filter(customDeck => customDeck.id !== payload.updatedObject.id)
        state.records.push(payload.updatedObject)
      }
    },
    [updateCustomDeck.rejected.toString()]: (state) => {
      state.status = SliceStatus.ERROR;
    },
    ...extraReducers,
  },
});

const updateCustomDecks = (
  customDeckORMs: CustomDeckORM[],
  isAutoUpdate: boolean = false,
  gracePeriodDays?: number
) => {
  let updateItemCount = 0
  customDeckORMs.forEach((deck) => {
    if (deck.meta.version.updateStatus !== VERSION_UPDATE_STATUS.CURRENT) {
      let hasChanges = false
      logger.customDeckSlice.debug(`Checking deck ${deck.model.id} for minor updates`)
      // Deck has minor changes pending now find applicable pages and check auto-update
      const updatedGroups = deck.meta.customDeckGroups.map((group) => {
        if (group.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR) {
          let newDocVersion: (DocumentVersionORM | undefined)
          const updatedPages = group.pages.map((page) => {
            if (page.documentVersionORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR) {
              const docORM = page.documentVersionORM.relations.documentORM
              const latestVersion = docORM.relations.version.latestPublishedDocumentVersionORM!
              // When is a call from applyAutoUpdate we will check the grace period
              const shouldUpdate = !isAutoUpdate || (isAutoUpdate &&
                isPast(addDays(new Date(latestVersion.model.updatedAt), gracePeriodDays!)))

              if (shouldUpdate) {
                // eslint-disable-next-line max-len
                logger.customDeckSlice.debug(`Identified page that needs update: ${page.model.pageId} needs to be updated to doc ${latestVersion.model.id}`)
                newDocVersion = latestVersion
                return {
                  pageId: `${latestVersion.model.id}_${page.model.pageNumber}`,
                  pageNumber: page.model.pageNumber,
                  documentVersionId: latestVersion.model.id,
                }
              }
            }
            return page.model
          })
          if (newDocVersion) {
            hasChanges = true
            return {
              ...group.model,
              srcId: newDocVersion.model.id,
              pages: updatedPages,
            }
          }
        }
        return group.model
      })
      if (hasChanges) {
        updateItemCount++
        // need to save new version of this deck
        logger.customDeckSlice.debug(`Updates detected for ${deck.model.id}. Updating...`)
        const now = new Date().toISOString();
        datastoreSave<CustomDeck>(CustomDeck, deck.model, {
          // Thanks to this issue we can't set to undefined
          // https://github.com/aws-amplify/amplify-js/issues/7565
          autoUpdateAcknowledgedAt: isAutoUpdate ? AUTO_UPDATE_DEFAULT_DATE : now,
          updatedAt: now,
          groups: updatedGroups,
        })
      }
    }
  })
  logger.clientState.hydrate.info(`Applying auto-updates to ${updateItemCount} Custom Decks`);
}

export default customDeckSlice;
export const customDeckActions = {
  lockCustomDeck,
  updateCustomDeck,
  ...customDeckSlice.actions,
}
