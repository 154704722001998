import { API, graphqlOperation, GraphQLQuery } from '@aws-amplify/api';
import {
  getCRMAuthInformation,
  getCRMAuthUrl,
  logOutCRM,
  refreshTokenCRM,
} from '@alucio/aws-beacon-amplify/src/graphql/queries';
import {
  GetCRMAuthInformationQuery,
  GetCRMAuthUrlQuery,
  LogOutCRMQuery,
  RefreshTokenCRMQuery,
} from '@alucio/aws-beacon-amplify/src/API';
import { BroadcastChannel } from 'broadcast-channel'

import { isIOS } from 'react-device-detect'

import jsforce from 'jsforce';
import { CRMAccount } from 'src/classes/CRM/CRMIndexedDBTypes';
import { Singleton as IndexDB } from 'src/classes/CRM/CRMIndexedDB';
import { CRMIntegration } from '@alucio/aws-beacon-amplify/src/models';
import get from 'lodash/get'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy';
import { EXTERNAL_TAB_EVENTS } from './crmMachineTypes';
import * as logger from 'src/utils/logger'

// Used to dispatch an event and let appsettings know that the CRM integration has been updated
export const CRM_STORAGE_UPDATE = 'CRM_STORAGE_UPDATE'
// Used as a key for the CRM integration session in local storage
export const CRM_AUTH_INFORMATION = 'CRM_AUTH_INFORMATION'
// used to save the code that crm give us to exchange for an access token and refresh token
export const CRM_CONNECTION_CLOSED = 'CRM_CONNECTION_CLOSED'

export const CRM_LAST_SYNC_AT = 'crmLastSyncedAt'

export const CRM_LAST_SYNC_STATUS = 'crmLastSyncStatus'

export const CRM_CODE = 'CRM_CODE'

export const INVALID_SESSION_ID = 'INVALID_SESSION_ID'

export const errors = {
  not_allowed_pop_up: 'Make sure you have pop-ups enabled for this site and try again.',
}

const CRM_MACHINE_CHANNEL = 'CRM_MACHINE_CHANNEL'
const crmBroadCastMessage = new BroadcastChannel(CRM_MACHINE_CHANNEL)

/* Types */
type AccountQuery = {
  Id: string,
  Name: string,
  LastModifiedDate: string,
}

export interface CRMIntegrationSession {
  accessToken: string
  refreshToken: string
  authInformation: {
    accessToken: string
    refreshToken: string
    instanceUrl: string
    userInfo: {
      id: string
      organizationId: string
      displayName: string
      thumbnail: string
    }
  }
}

export class CRMUtil {
  // used as a flag to determine if is a crm login or a sso login
readonly CRM_LOGIN_TRIGGERED = 'CRM_LOGIN_TRIGGERED'
// https://github.com/jsforce/jsforce-ajax-proxy

readonly API_VERSION = 'v56.0';

private sfConnectionInstance = () => {
  const authInformation = this.getAuthInformation()
  return this.getConnection(authInformation.instanceUrl, authInformation.accessToken)
}

public getAuthInformation = () => {
  const authInformation = localStorage.getItem(CRM_AUTH_INFORMATION)
    ? JSON.parse(localStorage.getItem(CRM_AUTH_INFORMATION) as string)
    : undefined;

  if (!authInformation) {
    logger.salesforceSyncer.debug('No CRM auth information found');
    return undefined;
  }

  return {
    ...authInformation,
    accessToken: authInformation.accessToken,
  };
}

private retryWithDelay = async <T>(fn: Function, attempts: number) => {
  let result;

  while (attempts > 0) {
    result = fn()
    if (result) {
      return result as T;
    }
    attempts--;
    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  return undefined;
}

private isError(e: any): e is { errors: {message: string, path: string[]}[] } {
  return Object.keys(e).includes('errors') &&
  e.errors instanceof Array &&
  e.errors.length > 0
}

/*
  * @param popUp - boolean to indicate if the login should be done in a pop up window
  */
getCRMAuthUrl = async (popUp: boolean) => {
  const { data } = await API.graphql<GraphQLQuery<GetCRMAuthUrlQuery>>(
    graphqlOperation(getCRMAuthUrl),
  );
  let popUpInstance: Window | null = null;
  const url = data?.getCRMAuthUrl;
  // we need to set a temp flag to indicate that we are in the middle of the CRM integration login process
  // this is used to prevent to trigger the sso login process
  // TODO BEAC-3746: try to handle this without using local storage
  localStorage.setItem(this.CRM_LOGIN_TRIGGERED, JSON.stringify({
    triggered: true,
    lastPath: window.location.pathname,
  }));

  if (isIOS && popUp && url && window?.location?.href) {
    // open the url for pwa supporting ios devices
    window.location.href = url
  }

  popUp
    ? url && (popUpInstance = window.open(url, '_blank', 'width=600,height=600'))
    : url && window.location.replace(url);

  if (!popUpInstance || popUpInstance === null || popUpInstance === undefined) {
    throw new Error(errors.not_allowed_pop_up);
  }

  if (popUpInstance) {
    const interval = setInterval(() => {
      if (popUpInstance?.closed) {
        this.triggerAuthPopUpClosed()
        clearInterval(interval);
      }
    }, 1000);
  }
}

/**
 * when the user trigger the login in a popup window, we need to wait until the popup is closed
 * and keep validating if the auth token is presented on the local storage
 * if the token is not presented and we trigger the auth logic we will generate an stale workflow
 * so here we are waiting n times to validate if the token is presented after that if the token is not presented
 * we will trigger the CRM_CONNECTION_CLOSED event to let the app know that the CRM integration login was closed
 */
triggerAuthPopUpClosed = async (retryIntervals = 3) => {
  for (let i = 0; i <= retryIntervals + 1; i++) {
    await this.delay(500)

    if (i > retryIntervals) {
      localStorage.removeItem(this.CRM_LOGIN_TRIGGERED);
      window.dispatchEvent( new CustomEvent(CRM_CONNECTION_CLOSED));
      break;
    }

    // there are some race conditions that the token is not presented on the local storage we need to wait until the token is presented
    const autInformation = await this.retryWithDelay<CRMIntegrationSession | undefined>(this.loadAuthInformation, 3)
    if (autInformation && autInformation.accessToken) {
      localStorage.removeItem(this.CRM_LOGIN_TRIGGERED);
      window.dispatchEvent( new Event(CRM_STORAGE_UPDATE));
      break;
    }
  }
}

/*
   Besides to clean the storage vars we need to invalidate the token
  */
logOutCRM = async (accessToken?: String) => {
  localStorage.removeItem(CRM_AUTH_INFORMATION);
  localStorage.removeItem(CRM_LAST_SYNC_AT);
  // By default storage event is if is triggered from other tab, but also we need to trigger it from the current tab
  window.dispatchEvent( new CustomEvent(CRM_CONNECTION_CLOSED) )

  if (!accessToken) {
    return
  }

  try {
    logger.CRMMachine.info(`Ending CRM auth session by logging out`)
    await API.graphql<GraphQLQuery<LogOutCRMQuery>>(
      graphqlOperation(logOutCRM, {
        accessToken,
      }),
    );
  }
  catch (e: unknown) {
    if (this.isError(e) && e.errors[0].message === 'Invalid session id') {
      console.warn('Invalid session id, most likely this happen when access token is expired')
    }
    else {
      throw e
    }
  }
}

/**
    * Get the CRM auth information from the local storage
    * Used when the user is already logged in on the CRM
    * @returns CRM auth information
   */
loadAuthInformation = () : CRMIntegrationSession | undefined => {
  const CRMSettings = this.getAuthInformation()

  if (!CRMSettings) {
    logger.salesforceSyncer.debug('No CRM auth information found');
    return undefined;
  }

  return {
    accessToken: CRMSettings?.accessToken || '',
    refreshToken: CRMSettings?.refreshToken || '',
    authInformation: {
      accessToken: CRMSettings?.accessToken || '',
      refreshToken: CRMSettings?.refreshToken || '',
      instanceUrl: CRMSettings?.instanceUrl || '',
      userInfo: {
        id: CRMSettings?.userInfo?.id || '',
        organizationId: CRMSettings?.userInfo?.organizationId || '',
        thumbnail: CRMSettings?.userInfo?.thumbnail || '',
        displayName: CRMSettings?.userInfo?.displayName || '',
      },
    },
  }
}

/*
    Exchange the code returned by the CRM for the access token and refresh token
    @param code - code returned by the CRM login workflow
    @returns CRM auth information Access token and refresh token
  */
getCRMAuthInformation = async (code: string) => {
  const { data } = await API.graphql<GraphQLQuery<GetCRMAuthInformationQuery>>(
    graphqlOperation(getCRMAuthInformation, {
      code: code,
    }),
  );

  if (!data) {
    console.error('No data returned from getCrmAuthInformation');
    return undefined;
  }

  localStorage.setItem(CRM_AUTH_INFORMATION, JSON.stringify(data?.getCRMAuthInformation));
  localStorage.removeItem(this.CRM_LOGIN_TRIGGERED)
  // By default storage event is if is triggered from other tab, but also we need to trigger it from the current tab
  window.dispatchEvent( new Event(CRM_STORAGE_UPDATE) )
  return data.getCRMAuthInformation;
}

/**
   * @param popUp - boolean to indicate if the login should be done in a pop up window
   * We need a way to trigger the CRM login workflow
   * so when the crm return the url with the code on the url
   * https://example.com/login?code=XFGFHJHJ
   * we can exchange the code for the access token and refresh token calling the getCRMAuthInformation GraphQL mutation
   * this method remove the code from the url and navigate to the latest url used by the user normally would be <profile />
   * we need to remove the code from the url to avoid having collisions with the cognito/amplify sso
   */
interceptCRMAuthCode = async (popUp: boolean) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code')
  const crmCode = localStorage.getItem(CRM_CODE)
  const CRMLogin = localStorage.getItem(this.CRM_LOGIN_TRIGGERED)
    ? JSON.parse(localStorage.getItem(this.CRM_LOGIN_TRIGGERED) as string)
    : undefined;

  /*
     If the login is triggered by CRM Integration, then we need to intercept the redirect and get the code
     issued by CRM Integration and reload the page without the code to avoid issues with the SSO flow
    */
  if (code && CRMLogin?.triggered) {
    localStorage.setItem(CRM_CODE, code)
    localStorage.removeItem(this.CRM_LOGIN_TRIGGERED)
    popUp
    // this will close the popup window
      ? window.close()
      : window.location.replace(`${window.location.origin}${CRMLogin.lastPath}`)
  }
  /*
      after the page is reloaded with the code, we can get the CRM Auth Information
      then we delete the code from the local storage
    */
  else if (crmCode) {
    const authInformation = await this.getCRMAuthInformation(crmCode)
    localStorage.setItem(CRM_AUTH_INFORMATION, JSON.stringify(authInformation))
    localStorage.removeItem(CRM_CODE)
  }
}

/*
    Refresh the access token and refresh token
  */
refreshTokenCRM = async (accessToken: String, refreshToken: String, dispatchEvent: boolean = true) => {
  const { data } = await API.graphql<GraphQLQuery<RefreshTokenCRMQuery>>(
    graphqlOperation(refreshTokenCRM, {
      accessToken,
      refreshToken,
    }),
  );

  if (!data) {
    console.error('No data returned from refreshTokenCRM');
    return undefined;
  }

  const authInformation = this.getAuthInformation()

  if (!authInformation) {
    console.error('No CRM auth information found');
    return undefined;
  }

  localStorage.setItem(CRM_AUTH_INFORMATION, JSON.stringify({
    ...authInformation,
    accessToken: data.refreshTokenCRM?.accessToken,
    issuedAt: data.refreshTokenCRM?.issuedAt,
  }))

  // only trigger event on the state machine, when is invoked as a helper function avoid to trigger the event to avoid the state machine to trigger the sync process
  if (dispatchEvent) {
  // By default storage event is if is triggered from other tab, but also we need to trigger it from the current tab
    window.dispatchEvent( new Event(CRM_STORAGE_UPDATE) )
  }
  return data.refreshTokenCRM;
}

/*
   Clean all the variables related to the CRM auth that may/may not be saved on the local storage
   */
cleanLogOutCRMStorage = () => {
  localStorage.removeItem(CRM_AUTH_INFORMATION);
  localStorage.removeItem(this.CRM_LOGIN_TRIGGERED);
  localStorage.removeItem(CRM_CODE);
  localStorage.removeItem(CRM_STORAGE_UPDATE);
  localStorage.removeItem(CRM_LAST_SYNC_AT);
  localStorage.removeItem(CRM_LAST_SYNC_STATUS);
  IndexDB.clearDB();
  window.dispatchEvent( new Event(CRM_STORAGE_UPDATE) )
}

// SALES FORCE API
getConnection = (instanceUrl: string, accessToken: string) : jsforce.Connection => {
  const conn = new jsforce.Connection(
    {
      instanceUrl,
      accessToken,
    });

  return conn;
}

private query = async (conn: jsforce.Connection, query: string) => {
  const accounts = await conn.query(query);
  return accounts;
}

private describeSObject = async (conn: jsforce.Connection, sObjectName: string) => {
  const describeSObject = await conn.describe(sObjectName);
  return describeSObject;
}

private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

private template = (tpl, args) => tpl.replace(/\${(\w+)}/g, (_, v) => args[v]);

getAccountData = async (config: CRMIntegration, lastSyncTime?: number) : Promise<{
  records: CRMAccount[],
  lastSyncTime: number,
} | undefined
> => {
  const conn = this.sfConnectionInstance();
  if (!conn) {
    return undefined;
  }
  // get Date with the minimum date
  const lastSyncDate = lastSyncTime ? new Date(lastSyncTime).toISOString() : new Date(0).toISOString()

  let records : CRMAccount[] = []
  let nextRecordsUrl = '';
  while (true) {
    const query = this.template(config.accountsSettings.query, { lastSyncDate: lastSyncDate });
    const accounts = await (nextRecordsUrl
      ? conn.request(nextRecordsUrl) : this.query(conn, query)) as jsforce.QueryResult<AccountQuery>;

    if (!accounts.records || !accounts?.records?.length) {
      break;
    }

    const items = accounts.records.map<CRMAccount>((account) => ({
      ...omit(account, ['Id']),
      id: account.Id,
      addresses: get(account, `${config.accountsSettings.addressSettings.alias}.records`, [])
        /** TODO: This typing should be unnecessary, see:
         * https://alucioinc.atlassian.net/browse/BEAC-4340
         */
        .map((address: {Id: string}) => ({
          id: address.Id,
          ...omit(address, ['Id']),
        })),
    }))

    records = [...records, ...items]

    if (accounts.nextRecordsUrl) {
      nextRecordsUrl = accounts.nextRecordsUrl;
    } else {
      break;
    }
  }

  return {
    // a bad query can return duplicated records
    // this will lead on multiple exceptions on the indexedDB
    records: uniqBy(records, 'id'),
    lastSyncTime: new Date().getTime(),
  }
}

externalTabHandler = (
  _callback: (event : EXTERNAL_TAB_EVENTS) => void,
  _receive: Function,
): void => {
  crmBroadCastMessage.onmessage = (event: any) => {
    const type = event?.data?.type ?? event?.type
    if (type === 'LOGOUT_CRM_FROM_OTHER_TAB') {
      _callback({ type: 'LOGOUT_CRM_FROM_OTHER_TAB' })
    }
    else if (type === 'LOGIN_CRM_FROM_OTHER_TAB') {
      // // TODO: find a way to do this in the state machine without syncing
      // reloadApp()
      _callback({ type: 'LOGIN_CRM_FROM_OTHER_TAB' })
    }
  }

  _receive((event: { type: string; }) => {
    if (event.type === 'LOGOUT_CRM_FROM_OTHER_TAB') {
      crmBroadCastMessage.postMessage({ type: 'LOGOUT_CRM_FROM_OTHER_TAB' })
    }
    else if (event.type === 'LOGIN_CRM_FROM_OTHER_TAB') {
      crmBroadCastMessage.postMessage({ type: 'LOGIN_CRM_FROM_OTHER_TAB' })
    }
  })
}

setOfflineCRM(): any {
  this.cleanLogOutCRMStorage()
}
}
