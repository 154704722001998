import React from 'react';
import {
  DNABox,
  DNACard,
  DNAText,
  DNADivider,
  luxColors,
  RadioItem,
} from '@alucio/lux-ui';
import { StyleSheet, StyleProp, TextStyle, TouchableOpacity } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { DocumentVersionChangeType } from '@alucio/aws-beacon-amplify/src/models';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';

export type DocumentVersionChangeTypeOrKey = DocumentVersionChangeType | keyof typeof DocumentVersionChangeType

const styles = StyleSheet.create({
  versionChangeHeader: {
    height: 56,
    paddingHorizontal: 24,
  },
  versionChangeBodyContainer: {
    padding: 24,
  },
  versionChangeItemDescription: {
    marginLeft: 32,
  },
  versionChangeMessage: {
    marginBottom: 24,
  },
  versionChangeItemText: {
    color: colors['color-gray-400'],
  },
  versionChangeFooter: {
    height: 56,
    borderTopColor: colors['color-gray-80'],
    borderTopWidth: 1,
    paddingHorizontal: 24,
  },
  hyperLink: {
    color: colors['color-primary-500'],
  },
});

interface BulletListItemProps {
  children: string | (string | React.JSX.Element)[],
}

interface BulletListItemGroupProps {
    children: (React.ReactElement<BulletListItemProps> | null)[],
    style?: StyleProp<TextStyle>,
}

interface VersionChangesPromptProps {
    disableMinor: boolean,
    changeType?: DocumentVersionChangeTypeOrKey,
    detectedChangeType: DocumentVersionChangeTypeOrKey,
    setChangeType: React.Dispatch<React.SetStateAction<DocumentVersionChangeTypeOrKey>>,
}

const BulletListItem = (props: BulletListItemProps) => {
  const { children } = props
  return (
    <DNABox alignY="center" spacing="xs">
      <DNAText
        status="flatAlt"
      >{'\u2022'}</DNAText>
      <DNAText
        status="flatAlt"
        style={{ paddingVertical: 3 }}
      >
        { children }
      </DNAText>
    </DNABox>
  )
}

const BulletListItemGroup = (props: BulletListItemGroupProps) => {
  const { style, children } = props
  return (
    <DNABox style={style} appearance="col" spacing="xs">
      { children.map((c, idx) => {
        if (c) return <BulletListItem key={idx} {...c.props} />
      },
      ) }
    </DNABox>
  )
}

const REVISION_MAJOR_MESSAGE = 'This new version includes major changes compared to the previous version.'
const SELECTION_MESSAGE: string = 'This selection determines the downstream impact ' +
  'to personalized files that use this file.';

const VersionChangesPrompt = (props: VersionChangesPromptProps) => {
  const { detectedChangeType, changeType, setChangeType, disableMinor } = props;
  const { setSelectedIndex } = usePublisherVersioningState();
  const enableMatchSlides = useFeatureFlags('BEAC_4251_slide_version_tracking');

  const message = detectedChangeType === 'MAJOR' ? REVISION_MAJOR_MESSAGE : ''
  return (
    <DNACard appearance="outline">
      <DNABox style={styles.versionChangeHeader} alignY="center">
        <DNAText bold h5>
          What type of changes were made to this new version?
        </DNAText>
      </DNABox>
      <DNADivider variant="versioning" />
      <DNABox style={styles.versionChangeBodyContainer} appearance="col">
        <DNABox style={styles.versionChangeMessage}>
          <DNAText style={ { color: luxColors.warning.quaternary }}>
            { message }
          </DNAText>
        </DNABox>
        <DNABox style={styles.versionChangeMessage}>
          <DNAText style={ { color: colors['color-gray-400'] }}>
            { SELECTION_MESSAGE }
          </DNAText>
        </DNABox>
        <DNABox>
          <DNABox appearance="col" fill spacing="md">
            <DNABox appearance="col" spacing="sm">
              <DNABox testID="minor-radio-button" >
                <RadioItem.Kitten
                  disabled={disableMinor}
                  checked={changeType === 'MINOR' && !disableMinor}
                  onChange={() => setChangeType(DocumentVersionChangeType.MINOR)}
                >
                  MINOR
                </RadioItem.Kitten>
              </DNABox>
              <DNABox style={styles.versionChangeItemDescription} appearance="col" spacing="sm">
                <DNAText bold style={styles.versionChangeItemText}>
                  This new version contains only minor changes and meets all of the following:
                </DNAText>
                <DNABox appearance="col" spacing="md">
                  <BulletListItemGroup>
                    <BulletListItem>
                      Order of existing slides has not changed
                    </BulletListItem>
                    <BulletListItem>
                      Slide settings (required status and/or grouping) have not changed
                    </BulletListItem>
                    {enableMatchSlides
                      ? (
                        <BulletListItem>
                          All slides have
                          <TouchableOpacity onPress={() => setSelectedIndex(1)}>
                            <DNAText style={styles.hyperLink}> matched </DNAText>
                          </TouchableOpacity>
                          to the previous version
                        </BulletListItem>
                      ) : null
                    }
                  </BulletListItemGroup>
                </DNABox>
              </DNABox>
            </DNABox>
            <DNABox appearance="col" spacing="sm">
              <DNABox testID="major-radio-button">
                <RadioItem.Kitten
                  checked={changeType === 'MAJOR' || disableMinor}
                  onChange={() => setChangeType('MAJOR')}
                >
                  MAJOR
                </RadioItem.Kitten>
              </DNABox>
              <DNABox style={styles.versionChangeItemDescription} appearance="col" spacing="sm">
                <DNAText bold style={styles.versionChangeItemText}>
                  This new version contains major changes including one of the following:
                </DNAText>
                <DNABox appearance="col" spacing="md">
                  <BulletListItemGroup>
                    <BulletListItem>
                      Existing slides have been removed
                    </BulletListItem>
                    <BulletListItem>
                      Order of existing slides has changed
                    </BulletListItem>
                    <BulletListItem>
                      Slide settings (required status and/or grouping) have changed
                    </BulletListItem>
                  </BulletListItemGroup>
                  <DNABox>
                    <DNAText numberOfLines={2} style={styles.versionChangeItemText}>
                      Field users would be required to review their impacted personalized files.
                    </DNAText>
                  </DNABox>
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>)
}

VersionChangesPrompt.displayeName = 'VersionChangesPrompt';

export default VersionChangesPrompt;
