import { createMachine, sendParent } from 'xstate';
import * as MatchSlides from './matchSlides.types';
import { assign } from '@xstate/immer';
import * as logger from 'src/utils/logger';

export const matchSlidesSM = createMachine(
  {
    id: 'MatchSlidesSM',
    tsTypes: {} as import('./matchSlides.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as MatchSlides.SMContext,
      events: {} as MatchSlides.SMEvents,
      services: {} as MatchSlides.SMServices,
    },
    context: {
      slidesGroupPoolVisible: false,
      hasNeedReviewSlide: false,
      hasEmptyMatchSlide: false,
    },
    initial: 'idle',
    states: {
      idle: {
        description: 'Waiting for user action',
        on: {
          SET_SLIDES_POOL_VISIBLE: {
            actions: 'setSlidesPoolVisible',
          },
          SET_HAS_UNRESOLVED_SLIDE_MATCHES: {
            actions: ['setUnresolvedSlideMatches', 'sendVersionDraftSync'],
          },
        },
      },
    },
  },
  {
    guards: {},
    actions: {
      setSlidesPoolVisible: assign((ctx, evt) => {
        ctx.slidesGroupPoolVisible = evt.payload
      }),
      setUnresolvedSlideMatches: assign((ctx, evt) => {
        ctx.hasNeedReviewSlide = evt.payload.hasNeedReviewSlide;
        ctx.hasEmptyMatchSlide = evt.payload.hasEmptyMatchSlide;
      }),
      sendVersionDraftSync: sendParent((ctx) => {
        logger.versioning.matchSlides.debug('Sending match slide sync...')

        return {
          type: 'MATCH_SLIDES_SYNC',
          payload: {
            hasNeedReviewSlide: ctx.hasNeedReviewSlide,
            hasEmptyMatchSlide: ctx.hasEmptyMatchSlide,
          },
        }
      }),
    },
    services: {},
  },
)

export default matchSlidesSM
