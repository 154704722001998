import React from 'react';
import { AlucioTheme, DNABox, DNAButton, DNAText, Iffy, LuxTheming } from '@alucio/lux-ui';
import DNATextLink from "@alucio/lux-ui/lib/components/type/DNAText/DNATextLink";
import Loading from 'src/screens/Loading';
import { logout } from '../../App';

export const LoggingOutLoader = () => {
  return (
    <LuxTheming config={AlucioTheme}>
      <DNABox alignX="center" alignY="center" style={{ height: '100vh' }}>
        <Loading context='Storage Quota'/>
      </DNABox>
    </LuxTheming>
  );
};

export const goToCloseAppAppleLink = () => {
  window.location.href = 'https://support.apple.com/en-us/HT212063';
};

const QuotaErrorMessage = () => {
  const isPWAStandalone = window.matchMedia('(display-mode: standalone)').matches;

  return (
    <LuxTheming config={AlucioTheme}>
      <DNABox alignX="center" alignY="center" style={{ height: '100vh' }}>
        <DNABox appearance="col" spacing="lg">
          <DNAText bold h2>Unfortunately, Beacon has encountered an error.</DNAText>
          <DNABox appearance="col" spacing="md">
            <DNAText bold h3>Here are some steps that may help:</DNAText>
            <DNABox appearance="col" spacing="sm">
              <DNAText h5 bold={false}>
                1. Close the application and restart.
                <Iffy is={isPWAStandalone}>
                  <DNAButton onPress={goToCloseAppAppleLink} appearance="ghostLink" padding="none">See how</DNAButton>
                </Iffy>
              </DNAText>
              <DNAText h5 bold={false}>
                2. Try
                <DNATextLink status="primary" onPress={logout} bold> logging out </DNATextLink>
                and logging back in</DNAText>
              <DNAText h5 bold={false}>3. If you continue to have issues, please contact support@alucio.io</DNAText>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </LuxTheming>
  );
};

export default QuotaErrorMessage;
