import React from 'react'
import { StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNAText, Iffy } from '@alucio/lux-ui'
import { ReportProvider } from 'src/components/Reports/ReportProvider';
import { ReportSelector } from 'src/components/Reports/ReportSelector';
import { EmbeddedReport } from 'src/components/Reports/EmbeddedReport';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

const styles = StyleSheet.create({
  header: {
    marginBottom: 0,
  },
  iFrame: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
  },
  mainWrapper: {
    paddingHorizontal: 48,
    paddingVertical: 20,
  },
  marginHorizontal: {
    marginHorizontal: 10,
  },
});

const publisherFolder = 'Beacon_Embedded_Publisher'
const FIGMA_ANALYTICS_DEMO_URL = 'https://embed.figma.com/proto/Euo76my4iCPmlWBh5kpcyp/Analytics?page-id=786%3A15789&' +
  'node-id=2730-4106&node-type=canvas&viewport=911%2C2923%2C0.53&scaling=min-zoom&content-scaling=fixed&' +
  'starting-point-node-id=2730%3A4106&hide-ui=1&embed-host=share';

const PublisherReports = () => {
  const enableDemoMode = useFeatureFlags('enableDemoMode');

  return (
    <ReportProvider folderName={publisherFolder}>
      <DNABox
        spacing="md"
        style={styles.mainWrapper}
        appearance="col"
        fill={!enableDemoMode}
      >
        { /* HEADER */}
        <DNABox alignY="center" spacing="between" style={styles.marginHorizontal}>
          <DNAText testID="page-title" h5 status="secondary">Analytics</DNAText>
        </DNABox>
        <DNADivider style={styles.marginHorizontal} />
        <Iffy is ={!enableDemoMode}>
          <ReportSelector />
          <DNABox fill>
            <EmbeddedReport />
          </DNABox>
        </Iffy>
        <Iffy is={enableDemoMode}>
          <DNABox fill>
            <iframe
              style={styles.iFrame}
              width="1240"
              height="1819"
              src={FIGMA_ANALYTICS_DEMO_URL}
              allowFullScreen
            />
          </DNABox>
        </Iffy>
      </DNABox>
    </ReportProvider >
  )
}

PublisherReports.displayName = 'Reports';

export default PublisherReports
