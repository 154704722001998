import React, { useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, Text, ViewProps } from 'react-native'
import { v4 } from 'uuid'
import type { StateFrom } from 'xstate'
import { useMachine } from '@xstate/react'
import { BroadcastChannel } from 'broadcast-channel';

import {
  DNABox,
  DNAText,
  DNAButton,
  DNAAspectRatio,
  Iffy,
  Stack,
  util,
  AlucioChannel,
} from '@alucio/lux-ui'
import {
  PlayerMode,
  PlayerEventSource,
  nonSelectableElementHook,
  PDFMessageTypes,
  PDFChannelMessage,
} from '@alucio/core'
import playerWrapperMachine, { PW } from 'src/state/machines/presentation/playerWrapper'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types'
import { FileType } from '@alucio/aws-beacon-amplify/src/models'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy'
import useCurrentPage from '../DNA/hooks/useCurrentPage'
import { DNARoute } from 'src/router/routeDef'
import DNALoader, { DNALoaderContexts, DNALoaderEvents } from 'src/components/DNA/Loader/DNALoader'
import { useHighlighter } from '../Highlighter/HighlighterProvider'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy'
import Highlighter from '../Highlighter/Highlighter'
import MeetingBackground from './MeetingBackground'
import DNASlideTransition from '../DNA/SlideTransition/DNASlideTransition'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import useLazyRef from 'src/hooks/useLazyRef'

type PlayerWrapperMachine = ReturnType<typeof playerWrapperMachine>
type PlayerWrapperState = StateFrom<PlayerWrapperMachine>

const styles = StyleSheet.create({
  watermarkContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    zIndex: 3,
    marginTop: 16,
    marginLeft: 16,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  webDocActionbar: {
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
    backgroundColor: colors['color-text-white'],
  },
  initialTextContainer: {
    backgroundColor: colors['color-black-transparent-40'],
  },
  initialTextCopy: { maxWidth: 558, textAlign: 'center', lineHeight: 24 },
  iFrameStackLayer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
  },
});

const useFormatted = (state: PlayerWrapperState) => {
  const currentState = JSON.stringify({ state: state.value, frameId: state.context.frameId })

  return {
    currentState,
  }
}

const useCond = (state: PlayerWrapperState) => {
  const isDocumentLoaded = state.hasTag(PW.Tags.IS_CONTENT_LOADED)
  const isIdle = state.hasTag(PW.Tags.IS_IDLE)

  return {
    isDocumentLoaded,
    isIdle,
  }
}

interface IFrameProps {
  autoScroll: boolean
  documentVersionId: string
  isBeingPresented: boolean
  iFrameSrc: string
  iFrameRef?: React.MutableRefObject<HTMLIFrameElement | null>
}

function IFrameComponent(props: IFrameProps) {
  const { autoScroll, documentVersionId, isBeingPresented, iFrameRef, iFrameSrc } = props;

  return (
    <DNABox fill appearance="col">
      <iframe
        id={`player-iframe-${documentVersionId}`}
        object-fit="cover"
        frameBorder={0}
        ref={isBeingPresented ? iFrameRef : undefined}
        scrolling={autoScroll ? 'auto' : 'no'}
        src={iFrameSrc}
        style={{
          flex: 1,
          overflow: 'hidden',
        }}
        key={iFrameSrc}
      />
    </DNABox>
  );
}

const MemoizedIFrame = React.memo(IFrameComponent);

interface IFrameWrapperProps {
  isFullWindow: boolean,
  meetingId: string,
  showDebug?: boolean,
  mode?: PlayerMode,
  /** TODO: Remove this once the content preview modal no longer supports full window mode on tablet */
  isTabletMeetingMode?: boolean,
  isDarkMode?: boolean,
  lockAspectRatio?: boolean,
  isFromMeetings?: boolean,
  wrapperStyle?: ViewProps['style'],
  isCustomDeck?: boolean,
  highlighterVisible?: boolean,
  returnToHome?: () => void,
  isExternalResourcesLoading?: boolean,
  analyticsEventType?: DNALoaderEvents,
  enableNew3PCFeatureFlag?: boolean,
  enableMultipleIFramesFeatureFlag?: boolean,
}

const IFrameWrapper: React.FC<IFrameWrapperProps> & { Highlighter: typeof PlayerWrapperHighlighter } = ({
  isFullWindow,
  meetingId,
  showDebug = false,
  mode = 'INTERACTIVE',
  isTabletMeetingMode,
  isDarkMode = true,
  lockAspectRatio = true,
  isFromMeetings = false,
  wrapperStyle,
  isCustomDeck,
  highlighterVisible,
  returnToHome,
  isExternalResourcesLoading,
  analyticsEventType,
  enableMultipleIFramesFeatureFlag,
}) => {
  const { deviceMode } = useAppSettings();
  const [hasPresented, setHasPresented] = useState<boolean>(false)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const currentPage = useCurrentPage();
  const content = useContent()
  const presentingDocId = useRef<string | undefined>()
  const playerChannel =
    useRef<BroadcastChannel<PDFChannelMessage>>(AlucioChannel.get(AlucioChannel.commonChannels.PDF_CHANNEL));
  const isInPerson = useMeetingsState()?.isInPerson;
  const popoutReference = useMeetingsState()?.popoutReference;
  const getPlayerContext = (currentPage: DNARoute | undefined): PlayerEventSource => {
    if (currentPage && currentPage.LABEL === 'Meetings') return PlayerEventSource.MEETING
    else if (currentPage && currentPage.PATH === '/publisher') return PlayerEventSource.PUBLISHER
    else return PlayerEventSource.APP
  }
  const playerContext = getPlayerContext(currentPage);
  const featureFlags = useFeatureFlags('enableNew3PC', 'enableMultipleIFrames')
  const enableMultipleIFrames = featureFlags.enableMultipleIFrames || !!enableMultipleIFramesFeatureFlag;

  const machine =
    useLazyRef(
      () => playerWrapperMachine(
        meetingId,
        mode,
        featureFlags.enableNew3PC,
        v4(),
        lockAspectRatio,
        playerContext,
        enableMultipleIFrames,
      ),
    ).current

  const [state, send] = useMachine(machine!)

  useEffect(() => {
    if (!state.tags.has(PW.Tags.IS_IDLE)) {
      setHasPresented(true)
    }
  }, [state])

  useEffect(() => {
    send('TOGGLE_HIGHLIGHTER', { highlighterVisible: highlighterVisible })
  }, [highlighterVisible])

  const formatted = useFormatted(state)
  const cond = useCond(state)

  useEffect(() => {
    const msg: PDFChannelMessage = {
      type: PDFMessageTypes.PAUSE_VIDEOS,
      frameId: state.context.lastPresentedIframeId || '',
      value: true,
    };

    playerChannel.current?.postMessage(msg);
  }, [state.context.frameId]);

  // Sync the Player Context's Loaded state with the Content context to block
  // the content context from state changes when the player is loading
  useEffect(() => {
    if (content) {
      if (!cond.isDocumentLoaded && !cond.isIdle) {
        content.setIsPlayerReady(false)
      } else {
        content.setIsPlayerReady(true)
        presentingDocId.current = content.activePresentation?.presentable.id
      }
    }
  }, [cond.isDocumentLoaded, cond.isIdle])

  const formatContentInfo = (data: PresentationBroadcastContent) => {
    const { ppzCoords, state, groupId, visiblePages, documentVersionId: docVerId } = data
    return JSON.stringify({
      docVersionId: `${docVerId.substring(0, 5)}...${docVerId.substring(docVerId.length - 5)}`,
      ppzCoords,
      state,
      groupId,
      visiblePages: visiblePages.join(','),
    }, null, '  ')
  }

  const isWebDoc = state.context.presentableState?.contentType === FileType.WEB
  const isVideoDoc = state.context.presentableState?.contentType === FileType.MP4
  const isHTMLDoc = state.context.presentableState?.contentType === FileType.HTML
  // ONLY THE OLD IN-PERSON MEETING NEEDS ADDITIONAL PADDING
  const additionalPadding = !featureFlags.enableNew3PC &&
    isInPerson &&
    isCustomDeck &&
    (isWebDoc || isVideoDoc || isHTMLDoc)
  const IframeWrapperRef = useRef<HTMLDivElement>(null)
  nonSelectableElementHook(IframeWrapperRef)
  const showSlideTransition = useMemo(() => {
    return enableMultipleIFrames &&
    !!state.context.presentableState?.customDeckId &&
    !cond.isIdle &&
    !cond.isDocumentLoaded
  }, [isFromMeetings, cond, enableMultipleIFrames, state.context.presentableState])
  const showLoader = useMemo(() => {
    const loadingDocument = (
      !showSlideTransition && (
        isExternalResourcesLoading ||
        (cond.isIdle && !isFromMeetings) ||
        (!cond.isIdle && !cond.isDocumentLoaded)
      ))
    const presentingAndSwitchToPresenterMode = !!(
      isFromMeetings &&
      !isInPerson &&
      content?.activePresentation &&
      !cond.isDocumentLoaded
    )
    return loadingDocument || presentingAndSwitchToPresenterMode
  }, [
    showSlideTransition,
    isExternalResourcesLoading,
    cond,
    isFromMeetings,
    content?.activePresentation,
  ])
  // eslint-disable-next-line max-len
  const currentIframeSrc = `/pdf/index.html?frameId=${state.context.frameId}&isFullWindow=${isFullWindow}&isTabletMode=${deviceMode === DeviceMode.tablet}&isTabletMeetingMode=${isTabletMeetingMode}`;

  const analyticsContext = isFromMeetings
    ? (hasPresented && isCustomDeck && presentingDocId.current === content.activePresentation?.presentable.id)
      ? DNALoaderContexts.DOCUMENT_CHANGE
      : DNALoaderContexts.DOCUMENT_INITIAL_LOAD
    : (hasPresented && isCustomDeck)
      ? DNALoaderContexts.DOCUMENT_CHANGE
      : DNALoaderContexts.DOCUMENT_INITIAL_LOAD

  const showMeetingBackground = useMemo(() => {
    return state.value === 'idle' &&
      isDarkMode &&
      isFromMeetings &&
      !(!isInPerson && content?.activePresentation)
  }, [
    state.value,
    isDarkMode,
    isFromMeetings,
    isInPerson,
    content?.activePresentation,
  ])

  const aspectRatio = useMemo(() => {
    const previousAspectRatio = Object.values(state.context.openedIFrames).find((object) =>
      object.iFrameId === state.context.lastPresentedIframeId)?.aspectRatio || state.context.aspectRatio;
    return showSlideTransition ? previousAspectRatio : state.context.aspectRatio;
  }, [showSlideTransition, state.context.openedIFrames, state.context.presentableState?.documentVersionId]);

  return (
    <DNABox
      ref={IframeWrapperRef}
      testID="content-preview-modal-iframe"
      fill
      appearance="col"
      style={util.mergeStyles(
        undefined,
        wrapperStyle,
        [{ backgroundColor: 'black' }, isDarkMode],
        [{ paddingBottom: 40 }, additionalPadding],
      )}
    >
      <Iffy is={state.can('RELOAD_WEB_DOC')}>
        <DNABox
          style={[styles.webDocActionbar, isFromMeetings ? { padding: 14 } : { paddingBottom: 14, marginBottom: 14 }]}
        >
          <DNAButton
            appearance="outline"
            status="tertiary"
            iconLeft="home"
            onPress={() => {
              returnToHome && returnToHome()
              send('RELOAD_WEB_DOC')
            }}
            testID="return-home-button"
          >
            Return home
          </DNAButton>
        </DNABox>
      </Iffy>
      <Iffy is={showDebug}>
        <DNAText h1>Current State</DNAText>
        <DNAText>{formatted.currentState}</DNAText>
        <DNABox appearance="row" fill>
          <DNABox appearance="col">
            <Text>
              Presentable State
              {state.context.presentableState ? formatContentInfo(state.context.presentableState) : ''}
            </Text>
          </DNABox>
          <DNABox appearance="col">
            <Text>
              Player State
              {state.context.playerState ? formatContentInfo(state.context.playerState) : ''}
            </Text>
          </DNABox>
        </DNABox>
      </Iffy>
      <Iffy is={showMeetingBackground}>
        <MeetingBackground mode={mode} hasPresented={hasPresented}/>
      </Iffy>
      <Iffy is={state.value !== 'idle'}>
        <DNABox fill appearance="col">
          <DNAAspectRatio ratio={aspectRatio} isFullWidth={isWebDoc || isHTMLDoc || isVideoDoc}>
            <Iffy is={!cond.isIdle}>
              {/* WATERMARK */}
              <Iffy is={state.context.presentableState?.watermarkText}>
                <DNABox style={styles.watermarkContainer}>
                  <DNAText bold h2 status="basic" >{state.context.presentableState?.watermarkText}</DNAText>
                </DNABox>
              </Iffy>
              {/* IFRAME */}
              <Stack >
                {/* by default the first stack layer is posicionated ad a relative for that reason we have this empty stack */}
                <Stack.Layer />
                {
                  !enableMultipleIFrames &&
                    <Stack.Layer fill style={wrapperStyle}>
                      <MemoizedIFrame
                        autoScroll={isWebDoc || isHTMLDoc}
                        documentVersionId={state.context.presentableState?.documentVersionId || ''}
                        iFrameRef={iFrameRef}
                        iFrameSrc={currentIframeSrc}
                        isBeingPresented
                      />
                    </Stack.Layer>
                }
                {
                  enableMultipleIFrames &&
                  Object.entries(state.context.openedIFrames).map(([documentVersionId, { iFrameId: frameId }]) => {
                    // eslint-disable-next-line max-len
                    const iFrameSrc = `/pdf/index.html?frameId=${frameId}&isFullWindow=${isFullWindow}&isTabletMode=${deviceMode === DeviceMode.tablet}&isTabletMeetingMode=${isTabletMeetingMode}`
                    const isPreviousIFrame = state.context.lastPresentedIframeId === frameId;
                    const isBeingPresented = documentVersionId === state.context.presentableState?.documentVersionId;
                    const showIFrame = (isPreviousIFrame && showSlideTransition) ||
                      (!showSlideTransition && isBeingPresented);
                    const presentedFileStyle = showIFrame ? { opacity: 1, zIndex: 1 } : {};
                    const wrapperStyle = { ...presentedFileStyle, ...styles.iFrameStackLayer };
                    return (
                      <Stack.Layer key={frameId} fill style={wrapperStyle}>
                        <MemoizedIFrame
                          autoScroll={isWebDoc || isHTMLDoc}
                          documentVersionId={documentVersionId}
                          iFrameRef={showIFrame ? iFrameRef : undefined}
                          iFrameSrc={iFrameSrc}
                          isBeingPresented={showIFrame}
                        />
                      </Stack.Layer>
                    );
                  })
                }
                {highlighterVisible &&
                  <Stack.Layer fill style={{ top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
                    <Highlighter iFrameRef={iFrameRef} mode={mode} popoutReference={popoutReference}/>
                  </Stack.Layer>
                }
              </Stack>
            </Iffy>
          </DNAAspectRatio>
        </DNABox>
      </Iffy>
      <Iffy is={showLoader}>
        <DNALoader
          isDarkMode={isDarkMode}
          analyticsEventType={analyticsEventType}
          analyticsContext={analyticsContext}
        />
      </Iffy>
      <Iffy is={showSlideTransition}>
        <DNASlideTransition
          analyticsEventType={analyticsEventType}
          analyticsContext={analyticsContext}
        />
      </Iffy>
    </DNABox>
  )
}

const PlayerWrapperHighlighter: React.FC<IFrameWrapperProps> = (props) => {
  const {
    highlighterVisible,
    resetHighlighter,
  } = useHighlighter();

  const {
    activePresentation,
  } = useContent()

  useEffect(() => {
    const documentId = activePresentation?.currentPresentablePage.documentVersionORM?.model.id
    if (documentId && props.mode === 'INTERACTIVE') {
      resetHighlighter()
    }
  }, [activePresentation?.currentPresentablePage.documentVersionORM?.model.id])

  const isHighlighterVisible = props.mode === 'INTERACTIVE' ? highlighterVisible : props.highlighterVisible

  return (
    <IFrameWrapper
      {...props}
      highlighterVisible={isHighlighterVisible}
      returnToHome={resetHighlighter}
    />
  )
}

IFrameWrapper.Highlighter = PlayerWrapperHighlighter;

export default IFrameWrapper;
