import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Icon, Iffy } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import FileUpload from 'src/components/DNA/FileUpload/FileUpload';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { VIDEO_EXTENSIONS } from 'src/utils/documentHelpers';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { ScheduleDocumentIcon } from 'src/components/DNA/GridList/common';
import { CopyCPMLink } from 'src/components/ContentPreviewModalV2/Util/useCPMDedicatedUrl';

const ERROR_MESSAGE = 'An error has occured, please try again. If this issue persists, please contact support';

export const S = StyleSheet.create({
  chevronIcon: {
    color: colors['color-gray-900'],
    height: 16,
  },
  headerActionsWrapper: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 20,
    shadowColor: colors['color-gray-900'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
  errorMessage: {
    zIndex: -1,
    padding: 12,
    backgroundColor: colors['color-danger-400'],
  },
  headerWrapper: {
    padding: 8,
  },
})

interface HeaderButtonProps {
  handleCreateFromExistingVersion: () => void,
  handleUploadNewVersion: (files: FileList | null) => void,
}

const HeaderNewVersionActions: React.FC<HeaderButtonProps> = (props) => {
  const { handleCreateFromExistingVersion, handleUploadNewVersion } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { service, documentORM } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.canCreateNewVersion,
      publisherVersioningSelector.isUploadingNewVersion,
      publisherVersioningSelector.canUploadNewVersion,
    ),
  )
  const fileExt = useRef<string[]>([]);

  const toggleVisibility = useCallback((callback?: () => void) => {
    typeof callback === 'function' && callback();
    setIsVisible((visible) => !visible);
  }, [setIsVisible])

  const selectNewVersion = useCallback((files: FileList | null) => {
    toggleVisibility();
    handleUploadNewVersion(files);
  }, [toggleVisibility, handleUploadNewVersion])

  const isWebDoc = documentORM.model.type === FileType.WEB
  const hasLatestPublishedVersion = documentORM.relations.version.latestPublishedDocumentVersionORM;
  const documentType = documentORM.model.type;

  if (hasLatestPublishedVersion) {
    switch (documentType) {
      case 'HTML':
        fileExt.current = ['ZIP'];
        break;
      case 'MP4':
        fileExt.current = VIDEO_EXTENSIONS;
        break;
      default:
        fileExt.current = [documentType];
    }
  } else {
    fileExt.current = ['PPTX', 'PDF', 'ZIP', ...VIDEO_EXTENSIONS];
  }

  // IF NO ACTION CAN BE MADE, NOTHING NEEDS TO BE RENDERED
  if (!cond.canCreateNewVersion && !cond.canUploadNewVersion) return null;
  return (
    <DNAPopover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={isVisible}
      onBackdropPress={toggleVisibility}
      type="menu"
    >
      <DNAPopover.Anchor>
        <DNAButton
          testID="create-new-version-button"
          onPress={() => toggleVisibility()}
          appearance="outline"
          status="tertiary"
          size="sm"
        >
          <DNABox spacing="sm" alignY="center">
            <DNAText>Create new version</DNAText>
            <DNABox>
              <Icon style={S.chevronIcon} name="chevron-down" />
            </DNABox>
          </DNABox>
        </DNAButton>
      </DNAPopover.Anchor>
      {/* CONTENT */}
      <DNAPopover.Content offset={5}>
        <DNABox
          style={S.headerActionsWrapper}
          appearance="col"
          spacing={!isWebDoc && cond.canUploadNewVersion ? 'md' : undefined}
        >
          {/* CREATE FROM EXISTING */}
          <Iffy is={cond.canCreateNewVersion}>
            <Pressable onPress={() => toggleVisibility(handleCreateFromExistingVersion)}>
              <DNAText numberOfLines={1}>
                Update document details (creates new version)
              </DNAText>
            </Pressable>
          </Iffy>
          {/* UPLOAD NEW VERSION BUTTON */}
          <Iffy is={!isWebDoc && cond.canUploadNewVersion}>
            <FileUpload.Input
              fileExt={fileExt.current}
              multiFile={false}
              processOnSelect={false}
              onSelect={selectNewVersion}
            >
              <Pressable>
                <DNAText>Upload new version</DNAText>
              </Pressable>
            </FileUpload.Input>
          </Iffy>
        </DNABox>
      </DNAPopover.Content>
    </DNAPopover>
  );
};

const Header: React.FC = () => {
  const {
    service,
    documentORM,
    currentDocumentVersionORM,
    handleSaveDraft,
    handleDeleteDraft,
    handleCreateFromExistingVersion,
    handleUploadNewVersion,
    handleExitButton,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.errors,
      publisherVersioningSelector.isScheduledViewMode,
      publisherVersioningSelector.isPublishedViewMode,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.hasProcessingError,
      publisherVersioningSelector.isProcessingNewVersion,
      publisherVersioningSelector.canDeleteDraft,
      publisherVersioningSelector.canSaveDraft,
      publisherVersioningSelector.disableDeleteDraftButton,
      publisherVersioningSelector.disableSaveDraftButton,
      publisherVersioningSelector.isModifying,
      publisherVersioningSelector.isPublishing,
      publisherVersioningSelector.isUploadingNewVersion,
      publisherVersioningSelector.isCancellingNewVersionUpload,
      publisherVersioningSelector.isExitBlocked,
    ),
  )

  const hasError = useMemo(() => Object.entries(cond.errors).length, [cond.errors]);

  const getShortenedText = (index: number, text?: string) => {
    if (!text) {
      return '';
    }
    const end = Math.min(text.length, index);
    return text.substring(0, end) + `${end < text.length ? '...' : ''}`;
  };

  const documentTitle = useMemo(() =>
    getShortenedText(70, currentDocumentVersionORM?.model.title), [currentDocumentVersionORM]);

  if (!currentDocumentVersionORM) return null;
  const status = ['ARCHIVED', 'REVOKED'].includes(documentORM.model.status)
    ? documentORM.model.status
    : currentDocumentVersionORM.model.status;
  const scheduledPublish = currentDocumentVersionORM.meta.schedule.publish.scheduledAt;
  const canCopyLink = (cond.isPublishedViewMode || cond.isInDraftingState) &&
    !cond.hasProcessingError &&
    !cond.isProcessingNewVersion &&
    // only allowed for the latest version
    currentDocumentVersionORM.model.id === documentORM.relations.version.latestDocumentVersionORM.model.id;

  return (
    <>
      <DNABox spacing="between" style={S.headerWrapper}>
        <DNABox appearance="col" spacing="sm" childFill={2}>
          {/* DOCUMENT TITLE */}
          <DNAText bold>{ documentTitle }</DNAText>
          {/* DOCUMENT BADGES */}
          <DNABox spacing="xs">
            <DNADocumentChip item={currentDocumentVersionORM} variant="semVer" />
            <DNADocumentChip item={currentDocumentVersionORM} variant="status" status={status} />
            <Iffy is={cond.isScheduledViewMode}>
              <ScheduleDocumentIcon mode="ICON" scheduledPublish={scheduledPublish}/>
            </Iffy>
          </DNABox>
        </DNABox>
        <DNABox spacing="xs">
          <DNABox style={{ paddingTop: 8 }} spacing="sm">
            {/* COPY LINK */}
            <Iffy is={canCopyLink}>
              <CopyCPMLink documentVersionId={currentDocumentVersionORM.model.id} />
            </Iffy>
            <DNABox spacing="md">
              {/* DELETE DRAFT BUTTON */}
              <Iffy is={cond.canDeleteDraft}>
                <DNAButton
                  testID="delete-draft-button"
                  appearance="outline"
                  status="tertiary"
                  size="sm"
                  onPress={handleDeleteDraft}
                  disabled={cond.disableDeleteDraftButton}
                  children="Delete draft"
                />
              </Iffy>
              {/* SAVE DRAFT BUTTON */}
              <Iffy is={cond.canSaveDraft}>
                <DNAButton
                  testID="save-draft-button"
                  appearance="outline"
                  status="tertiary"
                  size="sm"
                  onPress={handleSaveDraft}
                  disabled={cond.disableSaveDraftButton}
                  children="Save draft"
                />
              </Iffy>
              {/* CANCEL UPLOAD BUTTON */}
              <Iffy is={cond.isUploadingNewVersion}>
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  size="sm"
                  disabled={cond.isCancellingNewVersionUpload}
                  onPress={() => service.send('CANCEL_UPLOAD')}
                  // @ts-expect-error - Prop incompatibility, but it works fine
                  accessoryLeft={cond.isCancellingNewVersionUpload ? LoadingIndicator.Native : undefined}
                  children="Cancel upload"
                />
              </Iffy>
            </DNABox>
            {/* NEW VERSION ACTION BUTTONS */}
            <HeaderNewVersionActions
              handleCreateFromExistingVersion={handleCreateFromExistingVersion}
              handleUploadNewVersion={handleUploadNewVersion}
            />
          </DNABox>
          {/* EXIT BUTTON */}
          <DNAButton
            status="tertiary"
            appearance="ghostLink"
            size="md"
            iconLeft="close"
            disabled={cond.isExitBlocked}
            onPress={handleExitButton}
          />
        </DNABox>
      </DNABox>
      <Iffy is={hasError}>
        <DNABox style={S.errorMessage}>
          <DNAText status="basic">{ ERROR_MESSAGE }</DNAText>
        </DNABox>
      </Iffy>
    </>
  )
}
export default Header
