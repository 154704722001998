import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { Hub, HubPayload } from '@aws-amplify/core';
import subDays from 'date-fns/subDays';
import LoadingScreen, { useIsExternalPlayer } from 'src/screens/Loading'
import { Auth } from '@aws-amplify/auth'
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import {
  useDataStoreSubscription,
  useSyncMachineSubscription,
  useOfflineManifestSubscription,
} from 'src/state/datastore/subscriptions';
import hydrate from '../../state/datastore/hydrate';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
import { validate as uuidValidate } from 'uuid';
import {
  AttachedFile,
  CustomDeck,
  CustomFormRecord,
  Document,
  DocumentVersion,
  EmailTemplate,
  Folder,
  FolderStatus,
  Hub as BeaconHub,
  HubStatus,
  Meeting,
  Tenant,
  User,
  UserNotations,
  UserNotationsStatus,
} from '@alucio/aws-beacon-amplify/src/models'
import { useLDClient } from 'launchdarkly-react-client-sdk';
import AuthUtil from '../Authenticator/services/authUtil';
import useLogOut from '../Authenticator/LogOut';
import { useValidateUser } from '../Authenticator/useValidateUser';
import { DNALoaderEvents } from '../DNA/Loader/DNALoader';
import * as logger from 'src/utils/logger'
import useFeatureFlags from '../../hooks/useFeatureFlags/useFeatureFlags';

// Having tenantId declared at this level let us update the value when needed
// and ensure that the syncExpressions has the correct value
// when they are executed at DataStore.start() call
let tenantId = ''
let createdBy = ''

enum DATASTORE_EVENTS {
  READY = 'ready',
  STORAGE_SUBSCRIBED = 'storageSubscribed',
  SYNC_QUERIES_READY = 'syncQueriesReady',
  SYNC_QUERIES_STARTED = 'syncQueriesStarted',
}
type DataStoreEventKeys = keyof typeof DATASTORE_EVENTS

const UserInit: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const isHydrated = useSelector((state: RootState) => state.document.hydrated && state.emailTemplate.hydrated);
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const featureFlags = useFeatureFlags('enableNew3PC', 'enableSkipDatastoreOnReady');
  const isNew3PCEnabled = featureFlags.enableNew3PC || urlParams.get('enableNew3PC') === 'true';
  const [dataStoreReady, setDataStoreReady] = useState(isHydrated);
  const dataStoreSubscribed = useDataStoreSubscription(dataStoreReady);
  const isExternalPlayer = useIsExternalPlayer();
  const { isOfflineEnabled } = useAppSettings();
  const { isPerformingLogOut } = useLogOut();
  const syncMachineSubscribed = useSyncMachineSubscription(dataStoreReady, isOfflineEnabled);
  const isDataStorePublishingEventsRef = useRef<boolean>(false);
  const [isDataStoreHung, setIsDataStoreHung] = useState<boolean>(false);
  const dataStoreInitializing = useRef<boolean>(false)
  const observedEvents = useRef<Record<DataStoreEventKeys, HubPayload | undefined>>({
    READY: undefined,
    STORAGE_SUBSCRIBED: undefined,
    SYNC_QUERIES_READY: undefined,
    SYNC_QUERIES_STARTED: undefined,
  })

  useOfflineManifestSubscription();
  const LDClient = useLDClient();

  useEffect(() => {
    let removeListener: () => void | undefined
    let dataStoreTimeOut;
    const init = async () => {
      const initializer = isExternalPlayer ? 'External Player' : 'Beacon';
      logger.userInit.debug(`${initializer} initializing...`);
      if ((isExternalPlayer && !isNew3PCEnabled) || isPerformingLogOut) {
        return;
      }

      if (isOfflineEnabled !== undefined && LDClient && !isHydrated) {
        removeListener = Hub.listen('datastore', async (capsule) => {
          isDataStorePublishingEventsRef.current = true;
          const { payload: { event } } = capsule;

          if (event === DATASTORE_EVENTS.STORAGE_SUBSCRIBED) {
            observedEvents.current[DATASTORE_EVENTS.STORAGE_SUBSCRIBED] = capsule.payload
            logger.userInit.debug(`Got ${DATASTORE_EVENTS.STORAGE_SUBSCRIBED} event`, capsule)
          }

          if (event === DATASTORE_EVENTS.SYNC_QUERIES_STARTED) {
            observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_STARTED] = capsule.payload
            logger.userInit.debug(`Got ${DATASTORE_EVENTS.SYNC_QUERIES_STARTED} event`, capsule)
            logger.userInit.debug(`Waiting for ${DATASTORE_EVENTS.SYNC_QUERIES_READY} event`)
          }

          if (event === DATASTORE_EVENTS.SYNC_QUERIES_READY) {
            observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_READY] = capsule.payload
            logger.userInit.debug(`Got ${DATASTORE_EVENTS.SYNC_QUERIES_READY} event`, capsule)
          }

          if (event === DATASTORE_EVENTS.READY) {
            observedEvents.current[DATASTORE_EVENTS.READY] = capsule.payload
            logger.userInit.debug(`Got ${DATASTORE_EVENTS.READY} event`, capsule)
          }

          // [NOTE] - There seems to be a bug within DataStore that MAY fire the `ready` event pre-maturely
          //          - The consistency of this issue may be affected by the amount of records a Tenant may have
          //            where larger records counts are more likely to reproduce
          //        - Normally, we're under the assumption that the `ready` should be dispatched when all models have synced
          //          - This seems to be the normal case for both full syncs and even delta/partial sync
          //            - (Though we've seen one case where during a partial sync where it's fire before any delta syncs happen)
          //        - Since the `ready` event MAY fires pre-maturely, it allows the user to access the app sooner than it should
          //          which causes the internal DataStore processor to get backed up with too many requests
          //        - We instead rely on the `syncQueriesReady` event as it seems to always dispatch in the correct order when necessary
          //          - When the app HAS an internet connection, it will always attempt a sync which first dispatches the `syncQueriesStarted`
          //            - This happens when loading initially, refreshing and coming back online
          //            - Therefore, iff we see this event, we need to wait for `syncQueriesReady`
          //          - When the app does NOT have an internet connection, it will NOT dispatch `syncQueriesStarted`
          //            and subsequently not dispatch `syncQueriesReady`
          //            - So if we do NOT see this event `syncQueriesStarted`, fallback to just waiting for the normal `ready`
          const hasSyncQueriesStartedEvent = !!observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_STARTED]
          const hasSyncQueriesReadyEvent = !!observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_READY]
          const hasReadyEvent = !!observedEvents.current[DATASTORE_EVENTS.READY]
          const hasStorageSubscribedEvent = !!observedEvents.current[DATASTORE_EVENTS.STORAGE_SUBSCRIBED]

          const isDataStoreReadyOnline = (hasSyncQueriesStartedEvent && hasSyncQueriesReadyEvent)
          const isDataStoreReadyOffline = (!hasSyncQueriesStartedEvent && hasReadyEvent)
          const isDataStoreReadyPremature = (
            hasSyncQueriesStartedEvent &&
            !hasSyncQueriesReadyEvent &&
            hasReadyEvent
          )

          if (isDataStoreReadyPremature) {
            // [TODO] - Keep monitoring to see if that can happen during certain states and lock the user out due to `syncQueriesReady` never finishing
            //        - i.e., Offline-like scenarios, we may want to set a timeout and allow them to eventually enter the app
            //          - Or just wait for the DNALoader to suggest refresh/logout/etc
            logger.userInit.warn('RECEIVED PREMATURE READY EVENT', observedEvents.current)
          }

          // WE'LL SKIP THE READY EVENT FROM DATASTORE IF IT HAS PREVIOUSLY SYNCED ITS MODELS //
          // WE'LL SKIP BY DEFAULT DURING 3PC FOR THE EXTERNAL WINDOW
          const skipReadyEvent = (
            (featureFlags.enableSkipDatastoreOnReady || isExternalPlayer) &&
            localStorage.getItem('hasDatastoreHydrated') === 'true' &&
            hasStorageSubscribedEvent
          )

          const isDataStoreReady = (
            isDataStoreReadyOnline ||
            isDataStoreReadyOffline ||
            skipReadyEvent
          ) && !dataStoreInitializing.current

          if (isDataStoreReady) {
            // [NOTE] - Make sure we don't double initialize
            dataStoreInitializing.current = true
            logger.userInit.debug(
              'Starting datastore',
              {
                isDataStoreReadyOnline,
                isDataStoreReadyOffline,
                skipReadyEvent,
                observedEvents,
                dataStoreInitializing: dataStoreInitializing.current,
              },
            )
            if (skipReadyEvent) {
              // DataStore has already hydrated
              logger.userInit.debug('Skipping DataStore ready event');
            }
            await hydrate(isOfflineEnabled, LDClient);
            setDataStoreReady(true);
            logger.userInit.debug('Done hydrating redux')
            localStorage.setItem('hasDatastoreHydrated', 'true');
            removeListener();
          }
        });

        // IF NO EVENT IS PUBLISHED BY DATASTORE WITHIN 5 SECONDS
        // WE'LL DISPLAY THE "INSTRUCTIONS TO REFRESH" COMPONENT
        dataStoreTimeOut = setTimeout(() => {
          if (!isDataStorePublishingEventsRef.current) {
            setIsDataStoreHung(true);
          }
        }, 5000);

        // Configure datastore to use the tenantId GSI when querying dynamoDB rather than a scan
        let cognitoUser;
        const ninetyDaysAgo = subDays(new Date(), 90).toISOString();
        try {
          cognitoUser = await Auth.currentAuthenticatedUser();
        } catch (e) {
          if (localStorage.getItem('amplify-latest-user-attributes') !== null) {
            cognitoUser = JSON.parse(localStorage.getItem('amplify-latest-user-attributes') ?? '');
          } else {
            logger.userInit.error('Error getting current authenticated user', e);
          }
        }
        tenantId = cognitoUser?.attributes['custom:org_id']
        createdBy = cognitoUser?.attributes['custom:user_id']
        const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000);
        const syncExpressions = uuidValidate(tenantId) ? [
          syncExpression(AttachedFile, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(CustomDeck, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(Document, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(DocumentVersion, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(UserNotations, () => {
            return obj => obj.and(p => [p.createdBy.eq(createdBy), p.status.ne(UserNotationsStatus.DELETED)])
          }),
          syncExpression(EmailTemplate, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(Folder, () => {
            return obj => obj.or(p => [
              p.and(p => [p.tenantId.eq(tenantId), p.status.ne(FolderStatus.REMOVED)]),
              p.and(p => [p.tenantId.eq(tenantId), p.updatedAt.ge(oneHourAgo.toISOString())]),
            ])
          }),
          syncExpression(Tenant, () => {
            return obj => obj.id.eq(tenantId)
          }),
          syncExpression(User, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(Meeting, () => {
            return obj => obj.and(p => [p.createdBy.eq(createdBy), p.startTime.gt(ninetyDaysAgo)]);
          }),
          syncExpression(BeaconHub, () => {
            return obj => obj.and(p => [p.createdBy.eq(createdBy), p.status.ne(HubStatus.DELETED)]);
          }),
          syncExpression(CustomFormRecord, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
        ] : [];

        const LOCALSTORAGE_FULL_SYNC_INTERVAL_MIN = parseInt(localStorage.getItem('FULL_SYNC_INTERVAL_MIN') ?? '')
        const fullSyncInterval = isNaN(LOCALSTORAGE_FULL_SYNC_INTERVAL_MIN)
          ? undefined
          : LOCALSTORAGE_FULL_SYNC_INTERVAL_MIN

        if (fullSyncInterval) {
          logger.userInit.debug('Setting custom FULL_SYNC_INTERVAL (min)', fullSyncInterval)
        }

        DataStore.configure({
          syncPageSize: 250,
          errorHandler: (error) => logger.userInit.warn('Datastore Sync Error', error),
          syncExpressions: syncExpressions,
          fullSyncInterval
        })

        // Start the DataStore, this kicks-off the sync process.
        logger.userInit.debug('Calling dataStoreInit')
        AuthUtil.dataStoreInit()
      }
    }
    init()
    return () => {
      logger.userInit.debug('Cleanup function called, remove listener')
      clearTimeout(dataStoreTimeOut);
      removeListener?.();
    }
  }, [isOfflineEnabled, LDClient, isHydrated, isPerformingLogOut, isExternalPlayer]);

  const isDataStoreReady = (
    dataStoreReady &&
    dataStoreSubscribed &&
    (!isOfflineEnabled || syncMachineSubscribed) &&
    isHydrated
  )
  const isLoadingReady = isDataStoreReady || (isExternalPlayer && !isNew3PCEnabled);

  return isLoadingReady
    ? <>{children}</>
    : <LoadingScreen
        showRefreshComponent={isDataStoreHung}
        analyticsEventType={DNALoaderEvents.LOGIN}
        context='User Init - App Init'
    />
};

/*
  * This component is used to prevent the compenet will be rendered when the user is logging out or logging in
*/
const UserAuthWrapper: React.FC<PropsWithChildren> = (props) => {
  const { isPerformingLogOut } = useLogOut();
  const { isValidatingUser } = useValidateUser()
  const { isOnline } = useAppSettings();

  const { children } = props;

  if (isValidatingUser && isOnline) return <LoadingScreen context='User Init - Validating User' />
  if (isPerformingLogOut) return <LoadingScreen context='User Init - Logging Out'/>

  return (
    <UserInit>
      {children}
    </UserInit>
  )
}

export default UserAuthWrapper;
