import { useMemo } from 'react';
import { DocumentVersionORM } from 'src/types/orms';
import { GroupStatus, usePresentationBuilderState } from '../state/PresentationBuilderStateProvider';

export const useUnresolvedSlides = () => {
  const {
    selectedGroups,
    setShowUnavailableMessage,
    setDisablePreivew,
    allContentPageDataVersions,
    autoUpdateMapping,
  } = usePresentationBuilderState();

  const unresolvedArr = useMemo(() => {
    const resMap = new Map<string, { documentVersionORM: DocumentVersionORM, unresolvedCount: number }>();
    let disablePrev = false;
    let unavailable = false;
    const values = [GroupStatus.DELETED, GroupStatus.REVOKED, GroupStatus.ARCHIVED];

    unavailable = selectedGroups.some(({ groupStatus }) => values.includes(groupStatus));

    selectedGroups.forEach(group => {
      const { documentVersionORM, groupStatus } = group
      if (documentVersionORM && groupStatus === GroupStatus.MAJOR_UPDATE) {
        group.pages.forEach(page => {
          const newReplacementPageId = autoUpdateMapping[page.pageId]
          if (!newReplacementPageId) {
            const id = documentVersionORM.model.id;
            const unresolvedCount = (resMap.get(id)?.unresolvedCount ?? 0) + 1;
            resMap.set(id, {
              documentVersionORM,
              unresolvedCount,
            });
            disablePrev = true;
          }
        })
      }
    });

    disablePrev = unavailable || disablePrev || !selectedGroups.some(({ visible }) => visible);
    setShowUnavailableMessage(unavailable);
    setDisablePreivew(disablePrev);
    return Array.from(resMap.values());
  }, [allContentPageDataVersions, selectedGroups]);

  const unresolvedSlideCount = useMemo(() => {
    return unresolvedArr.reduce((accumulator, currVal) => accumulator + currVal.unresolvedCount, 0);
  }, [unresolvedArr]);

  return {
    unresolvedArr,
    unresolvedSlideCount,
  };
};
