import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import DNAError from 'src/components/DNA/Error';
import {
  MATCH_SLIDE_STATUS,
  MatchSlideStatesType,
  usePublisherVersioningState,
} from '../PublisherVersioningProvider';
import { RecyclerListView } from 'recyclerlistview';
import { RecyclerListViewState } from 'recyclerlistview/dist/reactnative/core/RecyclerListView';
import { RViewProps } from 'src/screens/Publishers/Versioning/MatchSlides/SlidesGroupPool';
import { DocumentVersionORM } from 'src/types/orms';
import { MatchSlidesService } from './matchSlides.types';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as matchSlidesSelector from 'src/state/machines/publisherVersioning/MatchSlides/matchSlides.selector';
import logger from 'src/utils/logger';
import { GroupedTargetItems } from 'src/components/DnD/DNASingleItemDnd';
import {
  addToDuplicateSlides,
  findKeysByItemId,
  removeFromDuplicateSlides,
} from 'src/state/machines/publisherVersioning/util';

export const IS_WARNING_THRESHOLD = 0.99;
export const NO_RECOMMENDATION_SCORE_THRESHOLD = 0.97;
export const SIMILARITY_SCORE_THRESHOLD = 0.005;

export interface MatchSlidesContextType {
  service: MatchSlidesService,
  slideGroupPoolRef: React.RefObject<RecyclerListView<RViewProps, RecyclerListViewState>>,
  slideGroupTargetRef: React.RefObject<RecyclerListView<RViewProps, RecyclerListViewState>>,
  currentDocumentVersionORM: DocumentVersionORM,
  latestPublishedDocumentVersionORM: DocumentVersionORM,
  slideCount: Record<string, number>,
  overContainer: string | null,
  setOverContainer: React.Dispatch<React.SetStateAction<string|null>>,
  toggleSlidesGroupPoolVisible: () => void,
  onUnmatch: (payload: string) => void,
  onMatch: (groupedTargetItems: GroupedTargetItems, pageId: string, mapping: string) => void,
  onRevert: (groupedTargetItems: GroupedTargetItems, pageId: string) => void,
  canRevert: (pageId: string, mapping?: string) => boolean,
  onScrollToIndexInSlideGroupPool: (index: number) => void,
  onScrollToIndexInSlideGroupTarget: (index: number) => void,
  onScrollToStatus: (status: MATCH_SLIDE_STATUS) => void,
}

export interface ProviderProps {
  children: ReactNode
}

export const MatchSlidesContext = createContext<MatchSlidesContextType>(null!);
MatchSlidesContext.displayName = 'MatchSlidesContext';

const MatchSlidesStateProvider: React.FC<PropsWithChildren<ProviderProps>> = ({

  children,
}) => {
  const {
    service: publisherVersioningService,
    mappedValues,
    latestDocumentVersionContentPageData,
    setLatestDocumentVersionContentPageData,
    matchSlideStates,
    setMatchSlideStates,
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
    documentORM,
    toggleSlider,
  } = usePublisherVersioningState();

  const [overContainer, setOverContainer] = useState<string|null>(null)

  /** STATE MACHINE */
  const service = useMachineSelector(
    publisherVersioningService,
    ctx => ctx.context.matchSlidesActor,
  ) as MatchSlidesService

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      matchSlidesSelector.slidesGroupPoolVisible,
    ),
  )

  const hasNeedReviewSlide = useMemo(() => {
    return Object
      .values(matchSlideStates)
      .some(state => state.status === MATCH_SLIDE_STATUS.NEEDS_REVIEW);
  }, [matchSlideStates])

  const hasEmptyMatchSlide = useMemo(() => {
    return Object
      .values(matchSlideStates)
      .some(state => state.status === MATCH_SLIDE_STATUS.NO_MATCH);
  }, [matchSlideStates]);

  useEffect(() => {
    service.send({
      type: 'SET_HAS_UNRESOLVED_SLIDE_MATCHES',
      payload: {
        hasNeedReviewSlide,
        hasEmptyMatchSlide,
      },
    })
  }, [hasNeedReviewSlide, hasEmptyMatchSlide])

  const setSlidesGroupPoolVisible = useCallback((slidesGroupPoolVisible: boolean) => {
    service.send({ type: 'SET_SLIDES_POOL_VISIBLE', payload: slidesGroupPoolVisible })
  }, [service])

  const toggleSlidesGroupPoolVisible = useCallback((): void => {
    setSlidesGroupPoolVisible(!cond.slidesGroupPoolVisible);
  }, [setSlidesGroupPoolVisible, cond.slidesGroupPoolVisible])

  const slideGroupPoolRef = useRef<RecyclerListView<RViewProps, RecyclerListViewState>>(null);
  const slideGroupTargetRef = useRef<RecyclerListView<RViewProps, RecyclerListViewState>>(null);

  /**
    * This function updates one or all slide's mapping in ContentPageData to 'USER_UNMATCHED'
    * @param payload payload is a string, if string is pageId one slide is unmatched, if payload is all, the entire document is unmatched
  */
  const onUnmatch = useCallback((payload: string) => {
    if (!latestDocumentVersionContentPageData) return;
    publisherVersioningService.send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
    if (payload === 'all') {
      setLatestDocumentVersionContentPageData(preState => {
        return preState.map(pageData => ({ ...pageData, mapping: 'USER_UNMATCHED' }))
      })
      setMatchSlideStates(preState => {
        return Object.keys(preState).reduce<MatchSlideStatesType>((acc, pageId) => {
          acc[pageId] = { status: MATCH_SLIDE_STATUS.NO_MATCH }
          return acc
        }, {})
      })
    } else {
      setLatestDocumentVersionContentPageData(preState => {
        return preState.map(pageData => {
          if (pageData.pageId === payload) {
            return { ...pageData, mapping: 'USER_UNMATCHED' }
          } else return pageData
        })
      })
      // check if there is still duplicate slides
      setMatchSlideStates(preState => {
        const duplicateSlides = preState[payload]?.duplicateSlides || [];
        const shouldRemoveDuplicateSlides = !!duplicateSlides.length;
        return {
          ...( shouldRemoveDuplicateSlides
            ? removeFromDuplicateSlides(preState, payload, duplicateSlides, latestDocumentVersionContentPageData)
            : preState
          ),
          [payload]: { status: MATCH_SLIDE_STATUS.NO_MATCH },
        };
      });
    }
  }, [
    publisherVersioningService,
    latestDocumentVersionContentPageData,
    setLatestDocumentVersionContentPageData,
    setMatchSlideStates,
  ])

  /**
    * This function updates one of the slide's mapping in ContentPageData based on the input
    * @param pageId is the new version page id
    * @param mapping is the previous mapping the new id is getting mapped to
  */
  const onMatch = useCallback((
    groupedTargetItems: GroupedTargetItems,
    pageId: string,
    mapping: string,
  ) => {
    if (!latestDocumentVersionContentPageData) return;
    publisherVersioningService.send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })

    // Step 1: check if the new mapping has been used
    const duplicateSlides = findKeysByItemId(groupedTargetItems, mapping).filter(id => id !== pageId);
    const shouldAddDuplicateSlides = !!duplicateSlides.length;

    // Step 2: update mapping in latestDocumentVersionContentPageData
    setLatestDocumentVersionContentPageData(preState => {
      return preState.map(pageData => {
        if (pageData.pageId === pageId) {
          return { ...pageData, mapping }
        } else return pageData
      })
    })

    // Step 3: update matchSlideStates:
    // check if the previous displayed pageId has duplicateSlides
    // remove the previousDuplicateSlides (if needed) and add the new duplicateSlides (if needed)
    setMatchSlideStates(preState => {
      const previousDuplicateSlides = preState[pageId]?.duplicateSlides || [];
      const shouldRemoveDuplicateSlides = !!previousDuplicateSlides.length;
      const removedDuplicateSlidesPreState = shouldRemoveDuplicateSlides
        ? removeFromDuplicateSlides(preState, pageId, previousDuplicateSlides, latestDocumentVersionContentPageData)
        : preState
      const addedDuplicateSlidesPreState = shouldAddDuplicateSlides
        ? addToDuplicateSlides(removedDuplicateSlidesPreState, pageId, duplicateSlides)
        : removedDuplicateSlidesPreState

      return {
        ...addedDuplicateSlidesPreState,
        [pageId]: {
          status: shouldAddDuplicateSlides ? MATCH_SLIDE_STATUS.NEEDS_REVIEW : MATCH_SLIDE_STATUS.MATCHED,
          duplicateSlides: shouldAddDuplicateSlides ? duplicateSlides : undefined,
        },
      };
    });
  }, [
    publisherVersioningService,
    latestDocumentVersionContentPageData,
    setLatestDocumentVersionContentPageData,
    setMatchSlideStates,
  ])

  /**
    * This function revert one of the slide's mapping in ContentPageData to the initial value (machine recommendation)
    * @param pageId is the new version page id
  */
  const onRevert = useCallback((
    groupedTargetItems: GroupedTargetItems,
    pageId: string,
  ) => {
    if (!latestDocumentVersionContentPageData) return;
    publisherVersioningService.send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })

    // Step 1: check if the new mapping has been used
    const targetPageData = latestDocumentVersionContentPageData.find(pageData => pageData.pageId === pageId);
    if (!targetPageData) return;
    const _recommendations = targetPageData.recommendations;
    const recommendations = _recommendations
      .filter(recommendation => recommendation.score >= NO_RECOMMENDATION_SCORE_THRESHOLD);
    const firstRecommendationId = recommendations[0]?.pageId as string | undefined;
    const recommendationIsBelowThreshold = recommendations[0]?.score < IS_WARNING_THRESHOLD;
    const hasSimilarRecommendations = !recommendationIsBelowThreshold && _recommendations.length > 1 &&
      Math.abs(_recommendations[0].score - _recommendations[1].score) <= SIMILARITY_SCORE_THRESHOLD;
    const duplicateSlides = firstRecommendationId
      ? findKeysByItemId(groupedTargetItems, firstRecommendationId).filter(id => id !== pageId)
      : [];
    const shouldAddDuplicateSlides = !!duplicateSlides.length;
    const isMatchedStatus = !recommendationIsBelowThreshold && !hasSimilarRecommendations && !duplicateSlides.length;

    // Step 2: update mapping in latestDocumentVersionContentPageData: we only want to assign mapping when
    // recommendation is not below IS_WARNING_THRESHOLD and the first 2 recommendations score are not too similar
    setLatestDocumentVersionContentPageData(preState => {
      return preState.map(pageData => {
        if (pageData.pageId === pageId) {
          if (firstRecommendationId && !recommendationIsBelowThreshold && !hasSimilarRecommendations) {
            return { ...pageData, mapping: firstRecommendationId }
          } else {
            return { ...pageData, mapping: null }
          }
        } else return pageData
      })
    })

    // Step 3: check if the previous displayed pageId has duplicateSlides, and needs to be removed
    // Step 4: update matchSlideStates:
    // remove the previousDuplicateSlides (if needed) and add the new duplicateSlides (if needed)
    setMatchSlideStates(preState => {
      const previousDuplicateSlides = preState[pageId]?.duplicateSlides || [];
      const shouldRemoveDuplicateSlides = !!previousDuplicateSlides.length;
      const removedDuplicateSlidesPreState = shouldRemoveDuplicateSlides
        ? removeFromDuplicateSlides(preState, pageId, previousDuplicateSlides, latestDocumentVersionContentPageData)
        : preState
      const addedDuplicateSlidesPreState = shouldAddDuplicateSlides
        ? addToDuplicateSlides(removedDuplicateSlidesPreState, pageId, duplicateSlides)
        : removedDuplicateSlidesPreState
      return {
        ...addedDuplicateSlidesPreState,
        [pageId]: {
          status: isMatchedStatus ? MATCH_SLIDE_STATUS.MATCHED : MATCH_SLIDE_STATUS.NEEDS_REVIEW,
          duplicateSlides: shouldAddDuplicateSlides ? duplicateSlides : undefined,
        },
      }
    })
  }, [
    publisherVersioningService,
    latestDocumentVersionContentPageData,
    setLatestDocumentVersionContentPageData,
    setMatchSlideStates,
  ])

  /**
    * This function check if a slide can be revert to the initial value (machine recommendation)
    * @param pageId is the new version page id
    * @param mapping is the current mapping in UI
    * @return boolean
  */
  const canRevert = useCallback((pageId: string, mapping?: string) => {
    const systemRecommendation: string | undefined = mappedValues[pageId]?.recommendations[0]?.pageId;
    return systemRecommendation !== mapping
  }, [mappedValues])

  const onScrollToIndexInSlideGroupPool = useCallback((index: number) => {
    if (!cond.slidesGroupPoolVisible) setSlidesGroupPoolVisible(true)
    setTimeout(() => {
      slideGroupPoolRef.current?.scrollToIndex(index, true)
    }, 500)
  }, [cond.slidesGroupPoolVisible, setSlidesGroupPoolVisible])

  const onScrollToIndexInSlideGroupTarget = useCallback((index: number) => {
    slideGroupTargetRef.current?.scrollToIndex(index, true)
  }, [])

  const onScrollToStatus = useCallback((status: MATCH_SLIDE_STATUS) => {
    const indexToScrollTo = Object.values(matchSlideStates).findIndex((matchSlideState) => {
      if (status === matchSlideState.status) return true
    })
    if (indexToScrollTo !== -1) slideGroupTargetRef.current?.scrollToIndex(indexToScrollTo, true)
  }, [matchSlideStates])

  const slideCount = useMemo(() => {
    return Object.values(matchSlideStates).reduce<Record<string, number>>((acc, matchSlideStates) => {
      if (matchSlideStates.status === MATCH_SLIDE_STATUS.NEEDS_REVIEW) acc.needsReview++
      else if (matchSlideStates.status === MATCH_SLIDE_STATUS.NO_MATCH) acc.noMatch++
      acc.total++
      return acc
    }, { noMatch: 0, needsReview: 0, total: 0 })
  }, [matchSlideStates])

  if (!latestPublishedDocumentVersionORM) {
    const errorText = `Could not find latestPublishedDocumentVersionORM: ${documentORM.model.id}`;
    logger.versioning.error(errorText);
    return (
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        style={{ backgroundColor: 'white' }}
      >
        <DNAError
          promptLogout={false}
          message="Could not open Document for versioning!"
        >
          <DNAText>{documentORM.model.id}</DNAText>
          <DNAButton
            appearance="ghost"
            onPress={() => toggleSlider()}
          >
            Go back
          </DNAButton>
        </DNAError>
      </DNABox>
    )
  }

  const context: MatchSlidesContextType = {
    slideGroupPoolRef,
    slideGroupTargetRef,
    service,
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
    slideCount,
    overContainer,
    setOverContainer,
    // FUNCTIONS
    toggleSlidesGroupPoolVisible,
    onUnmatch,
    onMatch,
    onRevert,
    canRevert,
    onScrollToIndexInSlideGroupPool,
    onScrollToIndexInSlideGroupTarget,
    onScrollToStatus,
  };

  return (
    <MatchSlidesContext.Provider value={context}>
      {children}
    </MatchSlidesContext.Provider>
  );
};

MatchSlidesStateProvider.displayName = 'MatchSlidesStateProvider';

export const useMatchSlidesState = () => {
  const context = useContext(MatchSlidesContext);
  if (!context) {
    throw new Error('useMatchSlidesState must be used within the MatchSlidesStateProvider');
  }
  return context;
};

export default MatchSlidesStateProvider;
