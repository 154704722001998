import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CustomDeck,
  CustomDeckGroup,
  CustomDeckPage,
  DocumentAccessLevel,
  DocumentStatus,
  EditMutex,
  FolderItemType,
  Page,
} from '@alucio/aws-beacon-amplify/src/models';
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty';
import { batch } from 'react-redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ClearSlides from 'src/components/DNA/Modal/DNAPresentationBuilder/ClearSlides'
import useEditorState from './useEditorState';
import RequiredSlidesHiddenConfirmation
  from 'src/components/DNA/Modal/DNAPresentationBuilder/RequiredSlidesHiddenConfirmation';
import CloseConfirmation from 'src/components/DNA/Modal/DNAPresentationBuilder/CloseConfirmation';
import {
  PRESENTATION_BUILDER_VARIANT,
  presentationBuilderActions,
  EDITOR_TYPE,
} from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder';
import { folderActions } from 'src/state/redux/slice/folder';
import useSelectorState from './useSelectorState';
import { ThumbnailDimensions, ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { RootState, useDispatch, useSelector } from 'src/state/redux';
import {
  CustomDeckGroupORM,
  CustomDeckORM,
  DocumentVersionORM,
  FolderItemORM,
  FolderORM,
  ORMTypes,
  VERSION_UPDATE_STATUS,
} from 'src/types/types';
import { useCustomDeckORMById } from 'src/state/redux/selector/folder';
import { v4 as uuid } from 'uuid';
import { contentPreviewModalActions } from '../../../state/redux/slice/contentPreviewModal';
import { customDeckActions } from 'src/state/redux/slice/customDeck'
import { userNotationsActions } from 'src/state/redux/slice/userNotations';
import { useCurrentUser } from 'src/state/redux/selector/user';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { useAppSettings } from 'src/state/context/AppSettings';
import { ThumbnailPage } from 'src/components/SlideSelector/SlideSelector';
import SelectFolder from '../SelectFolderPresentationBuilder';
import { UpdateType, sharedFolderActions } from 'src/state/redux/slice/sharedFolder';
import { isSharedEditableFolder } from 'src/utils/foldersHelpers';
import { GroupDraftStatus, ReplaceGroupDraft } from '../PresentationEditor/FindReplacement/FindReplacementProvider';
import * as logger from 'src/utils/logger';
import { ContentPageData, getS3KeysWithinCustomDeck } from 'src/hooks/useContentPageData/useContentPageData';
import { fetchPagesJson } from 'src/utils/loadCloudfrontAsset/common';
import { getCurrentDocVer } from 'src/state/machines/sharing/shareUtils';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { assignReplacementGroups } from 'src/state/machines/findReplacement/findReplacementUtils';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

/** These options represent the different 'modes' this component can be in. The default is 'selection' mode */
enum BuilderModes {
  selection,
  customization,
  replace,
}

const TIME_LAST_SEEN_MINUTES = 15
const MUTEX_INTERVAL = 60 * 1000

export type BuilderMode = keyof typeof BuilderModes

/** State properties which can be used by various sub-components to manipulate the current in-progress presentation */
export interface PresentationBuilderStateType {
  clearSlides: () => void,
  closePresentationBuilder: (save?: boolean) => void,
  savePresentation: () => void,
  saveFindReplacement: (
    updatedPayloadGroups: PayloadGroup[],
    replaceGroupDraft: ReplaceGroupDraft[],
    callBack?: () => void,
  ) => void,
  builderMode: BuilderMode,
  setBuilderMode: React.Dispatch<React.SetStateAction<BuilderMode>>,
  title: string | undefined,
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>,
  saveAttemptedWithoutTitle: boolean,
  setSaveAttemptedWithoutTitle: React.Dispatch<React.SetStateAction<boolean>>,
  numOfRequiredSlides: number,
  setNumOfRequiredSlides: React.Dispatch<React.SetStateAction<number>>,
  selectedGroups: PayloadGroup[],
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>,
  visibleGroupsMap: Record<string, boolean>,
  associatedParentsMap: AssociatedParentsMap,
  setAssociatedParentsMap: React.Dispatch<React.SetStateAction<AssociatedParentsMap>>,
  hiddenSlidesVisible: boolean,
  setHiddenSlidesVisible: React.Dispatch<React.SetStateAction<boolean>>,
  showReviewRequiredOnly: boolean,
  setShowReviewRequiredOnly: React.Dispatch<React.SetStateAction<boolean>>,
  displayPageSourceFile: boolean,
  setDisplayPageSourceFile: React.Dispatch<React.SetStateAction<boolean>>,
  handleModeChange: (nextMode?: BuilderMode) => void,
  editorThumbnailSize: ThumbnailSize,
  setEditorThumbnailSize: React.Dispatch<React.SetStateAction<ThumbnailSize>>,
  cycleEditorThumbnailSize: () => void,
  editorThumbnailDimensions: ThumbnailDimensions,
  selectorThumbnailSize: ThumbnailSize,
  setSelectorThumbnailSize: React.Dispatch<React.SetStateAction<ThumbnailSize>>,
  cycleSelectorThumbnailSize: () => void,
  selectorThumbnailDimensions: ThumbnailDimensions,
  onPreview: () => void,
  cancelPresentation: () => void,
  activeReplacementGroup?: PayloadGroup,
  setActiveReplacementGroup: React.Dispatch<React.SetStateAction<PayloadGroup | undefined>>,
  onApplyFindAndReplace: (payloadGroups: PayloadGroup[], newTitle?: string) => ExtendedPagePayloadGroup[],
  customDeck?: CustomDeckORM,
  editorType?: EDITOR_TYPE,

  /** TODO: These three properties seem closely interrelated, should investigate the posibility of consolidation */
  variant?: PRESENTATION_BUILDER_VARIANT,
  isCustomDeckLockedByOthers?: boolean,
  isLocked?: boolean,

  selectedTargetItems: string[],
  setSelectedTargetItems: React.Dispatch<React.SetStateAction<string[]>>,
  disablePreview: boolean,
  setDisablePreivew: React.Dispatch<React.SetStateAction<boolean>>,
  showUnavailableMessage: boolean,
  setShowUnavailableMessage: React.Dispatch<React.SetStateAction<boolean>>,
  findReplacementVisible: boolean,
  setFindReplacementVisible: React.Dispatch<React.SetStateAction<boolean>>,
  openFindReplacement: (group: ModifiedPayloadGroup) => void,
  hasNeedReviewGroup: boolean,

  onRemoveGroup: (group: PayloadGroup) => void,
  onRemoveAllUnavailableGroups: () => void,
  onSeparateGroup: (group: PayloadGroup) => void,
  isSingleAndHasAssociation: (group: ModifiedPayloadGroup) => boolean,
  canRemoveGroup: (group: PayloadGroup) => boolean,
  targetFolder?: FolderORM,
  allContentPageDataVersions: { [documentVersionId: string]: ContentPageData[] },
  acceptAutoUpdateSlides: () => void,
  autoUpdateMapping: Record<string, string>,
}

export interface PayloadGroup {
  id: string;
  groupSrcId?: string;
  documentVersionORM?: DocumentVersionORM;
  pages: Page[];
  visible: boolean;
  groupStatus: GroupStatus,
  docAccessLevel: keyof typeof DocumentAccessLevel,
  name?: string;
  locked?: boolean;
}

export type AssociatedParentsMap = Map<string, boolean>

/** NOTE: normally we'd have to use an Omit<PayloadGroup, 'pages'> here but since TS doesn't
 * care about switching from `Page` to the extended type `ThumbnailPage` it's fine */
export interface ModifiedPayloadGroup extends PayloadGroup {
  pages: ThumbnailPage[]
}

interface ExtendedPage extends Page, Pick<ThumbnailPage, 'parentIds'> { }
interface ExtendedPagePayloadGroup extends PayloadGroup {
  pages: ExtendedPage[]
}

export enum GroupStatus {
  MAJOR_UPDATE = 'MAJOR_UPDATE',
  MINOR_UPDATE_WITH_AUTO_MAPPING_SLIDES = 'MINOR_UPDATE_WITH_AUTO_MAPPING_SLIDES',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
  REVOKED = 'REVOKED',
  ACTIVE = 'ACTIVE',
}

export const unavailableStatus = {
  [GroupStatus.REVOKED]: true,
  [GroupStatus.DELETED]: true,
  [GroupStatus.ARCHIVED]: true,
}

/** Type defining config props for the <PresentationBuilderStateProvider> element */
interface PresentationBuilderStateProviderProps {
}

/** Context instantiation/config */
export const PresentationBuilderStateContext = createContext<PresentationBuilderStateType>(null!)
PresentationBuilderStateContext.displayName = 'PresentationBuilderContext'

/**
 * Identify the current status of the document, as an example if the documente should be removed or be replaced by another one
 * @param documentVersion
 * @returns MAJOR_UPDATE, DELETED, ARCHIVED, ACTIVE
 */
export const getPayloadGroupStatus = (documentVersion?: DocumentVersionORM): GroupStatus => {
  if (!documentVersion) return GroupStatus.DELETED;

  const isMajor = documentVersion?.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR
  const isNotPublished = documentVersion?.meta.version.updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED

  if (isMajor) {
    return GroupStatus.MAJOR_UPDATE
  }
  else if (isNotPublished) {
    const status = documentVersion?.relations.documentORM.model.status as DocumentStatus
    if ([DocumentStatus.ARCHIVED].includes(status!)) {
      return GroupStatus.ARCHIVED
    }
    else if ([DocumentStatus.REVOKED].includes(status!)) {
      return GroupStatus.REVOKED
    }
    else if ([DocumentStatus.DELETED].includes(status!)) {
      return GroupStatus.DELETED
    }
    // Not sure if is a real posibility
    else {
      return GroupStatus.ACTIVE;
    }
  }
  else {
    return GroupStatus.ACTIVE
  }
}

/* When saving, convert our draft object into a CustomDeckGroup[] */
const payloadGroupsToCustomDeckGroup = (
  draftGroups: PayloadGroup[],
  existingCustomDeck?: CustomDeckORM,
) => {
  return draftGroups.map((draftGroup) => {
    let pages: CustomDeckPage[] = [];

    if (draftGroup.groupStatus === GroupStatus.DELETED) {
      // IF THE DOCUMENT WAS DELETED (MEANING THAT THE PAGES ARE EMPTY)
      // WE WANT TO KEEP THE REFERENCES AS THEY'RE IN THE CUSTOMDECK SO THEY'RE STILL
      // SHOWN AS REQUIRED TO BE MANUALLY REMOVED
      const savedGroup = existingCustomDeck?.model.groups.find(({ id }) => id === draftGroup.id);
      pages = savedGroup?.pages || [];
    } else {
      pages = draftGroup.pages.map((page) => ({
        pageId: page.pageId,
        pageNumber: page.number,
        documentVersionId: draftGroup.documentVersionORM?.model.id || '',
      }))
    }

    const newCustomDeckGroup:CustomDeckGroup = {
      id: draftGroup.id,
      srcId: draftGroup.groupSrcId ?? draftGroup.documentVersionORM?.model.id,
      pages,
      visible: draftGroup.visible,
      docAccessLevel: draftGroup.docAccessLevel,
      name:draftGroup.name,
      locked: draftGroup.locked,
    }
    return newCustomDeckGroup
  });
}

/* FUNCTIONS TO CREATE A DRAFT FOLDERITEMORM TO USE THE CUSTOM DECK IN THE PLAYER */
const customDeckORMToPreview = (groups: PayloadGroup[], customDeck?: CustomDeckORM) => {
  const groupORMs: CustomDeckGroupORM[] = [];
  const validGroups = groups.filter(({ groupStatus }) => groupStatus === GroupStatus.ACTIVE);
  const customDeckGroups: CustomDeckGroup[] = payloadGroupsToCustomDeckGroup(validGroups, customDeck);
  const now = new Date().toISOString();
  const existingUserNotations = customDeck?.relations.userNotations

  validGroups.forEach((group) => {
    /** NOTE: This might be better structured as a map to reduce the overhead from iteration */
    const customDeckGroup = customDeckGroups.find(({ id }) => id === group.id)

    if (!customDeckGroup) throw (new Error('No custom deck group found, this may indicate a corruped DB entry'))

    groupORMs.push({
      isGroup: group.pages.length > 1,
      model: customDeckGroup,
      pages: group.pages.map((page) => ({
        documentVersionORM: group.documentVersionORM!,
        page,
        model: {
          documentVersionId: group.documentVersionORM?.model.id!,
          pageNumber: page.number,
          pageId: page.pageId,
        },
      })),
      meta: {
        version: {
          updateStatus: VERSION_UPDATE_STATUS.CURRENT,
        },
      },
    });
  });

  const newCustomDeckORM: CustomDeckORM = {
    model: {
      id: uuid(),
      title: customDeck?.model.title ?? '',
      createdAt: now,
      updatedAt: now,
      createdBy: '',
      autoUpdateAcknowledgedAt: now,
      updatedBy: '',
      tenantId: '',
      groups: customDeckGroups,
    },
    type: ORMTypes.CUSTOM_DECK,
    meta: {
      formattedNotations: {
        documentLevel: {},
        customDeckLevel: {},
      },
      customDeckGroups: groupORMs,
      assets: {
        isContentCached: false,
      },
      version: {
        updateStatus: VERSION_UPDATE_STATUS.CURRENT,
        requiresReview: false,
        autoUpdateUnacknowledged: false,
        withinGracePeriod: false,
      },
      permissions: {
        isCollaborator: false,
        MSLPresent: false,
        MSLShare: false,
        MSLDownload: false,
      },
      containsWebDocs: false,
      containsVideoDoc: false,
      containsHTMLDocs: false,
      hasExternalDependency: false,
    },
    relations: {
      userNotations: existingUserNotations,
    },
  }

  return newCustomDeckORM
}

/* CREATES A DRAFT FOLDER ITEM SO WE CAN SEND IT TO THE CONTENT PREVIEW MODAL AS A PREVIEW OF THE NEW CUSTOM DECK */
function toFolderItemORM(
  selectedGroups: PayloadGroup[],
  customTitle?: string, customDeckORM?: CustomDeckORM,
  folderItemORM?: FolderItemORM): FolderItemORM {
  const draftCustomDeckORM = customDeckORMToPreview(selectedGroups, customDeckORM);

  if (folderItemORM) {
    return {
      ...folderItemORM,
      meta: {
        title: folderItemORM.meta.title,
        assets: {},
        hasAutoUpdatedItem: false,
        hasOutdatedItem: false,
      },
      relations: {
        itemORM: draftCustomDeckORM,
      },
    }
  }

  const now = new Date().toISOString();

  return {
    model: {
      id: uuid(),
      customTitle: customTitle || 'Presentation Preview',
      type: FolderItemType.CUSTOM_DECK,
      itemId: uuid(),
      itemLastUpdatedAt: now,
      addedAt: now,
    },
    meta: {
      title: draftCustomDeckORM.model.title,
      assets: {},
      hasAutoUpdatedItem: false,
      hasOutdatedItem: false,
    },
    type: ORMTypes.FOLDER_ITEM,
    relations: {
      itemORM: draftCustomDeckORM,
    },
  };
}

export const timeSinceLastSeen = (editMutex: EditMutex) => {
  const now = new Date()
  const lastUpdatedAt = new Date(editMutex.lastSeenAt)

  const diff = now.getTime() - lastUpdatedAt.getTime()
  const diffMinutes = Math.floor(diff / (1000 * 60))

  return diffMinutes
}

/** Provider instantiation/config */
const PresentationBuilderStateProvider: React.FC<PropsWithChildren<
  PresentationBuilderStateProviderProps
>> = (props) => {
  const globalBuilderState = useSelector((state: RootState) => state.PresentationBuilder);
  const { targetFolder, folderItemORM, customDeckId, variant, editorType } = globalBuilderState;
  const { isOnline } = useAppSettings()
  const route = useCurrentPage({ exact: false })
  const isSharedFolders = route?.PATH.includes('shared_folders');
  const existingCustomDeck = useCustomDeckORMById(customDeckId || '');
  // We set to read only by default to allow the fetching of the lock
  const [isLocked, setIsLocked] = useState(!!existingCustomDeck && isOnline)
  const [builderMode, setBuilderMode] = useState<BuilderMode>(existingCustomDeck ? 'customization' : 'selection');
  const [title, setTitle] = useState<string | undefined>(folderItemORM?.meta.title ?? '')
  const [saveAttemptedWithoutTitle, setSaveAttemptedWithoutTitle] = useState<boolean>(false)
  const [selectedTargetItems, setSelectedTargetItems] = useState<string[]>([])
  const [numOfRequiredSlides, setNumOfRequiredSlides] = useState<number>(0)
  const [findReplacementVisible, setFindReplacementVisible] = useState(false);
  const initialTitleRef = useRef(title)

  /** If we're editing, we'll convert the CUSTOMDECKORM into our draft object **/
  const toCustomDeckPayloadGroups = (customDeckORM: CustomDeckORM) => {
    return customDeckORM.meta.customDeckGroups.map(
      (groupORM) => {
        const documentVersionORM = groupORM.pages[0].documentVersionORM;
        const groupStatus = getPayloadGroupStatus(documentVersionORM);

        // Calculate 'parentIds' and attach it in page, calculate 'associatedParents' and send to context
        const allAssociatedParents: {[key: string]: string[]} = {}
        documentVersionORM?.meta.allPages.forEach(page => {
          page.linkedSlides?.forEach(slideId => {
            if (allAssociatedParents[slideId]) allAssociatedParents[slideId].push(page.pageId)
            else allAssociatedParents[slideId] = [page.pageId]
          })
        })

        const pages = groupORM.pages.map(({ page }) => {
          const parentIds: string[] = []
          if (allAssociatedParents[page.pageId]) parentIds.push(...allAssociatedParents[page.pageId])
          return {
            ...page,
            parentIds,
          }
        })

        const mappedPayloadGroup: PayloadGroup = {
          id: groupORM.model.id,
          groupSrcId: groupORM.model.srcId,
          documentVersionORM,
          pages,
          visible: groupORM.model.visible,
          groupStatus,
          docAccessLevel: groupORM.model.docAccessLevel,
          name: groupORM.model.name,
          locked: groupORM.model.locked,
        }

        return mappedPayloadGroup
      },
    );
  }

  const [selectedGroups, setSelectedGroups] = useState<PayloadGroup[]>(
    existingCustomDeck
      ? toCustomDeckPayloadGroups(existingCustomDeck)
      : [],
  );
  const hasNeedReviewGroup = useMemo(
    () => selectedGroups.some(group => group.groupStatus !== GroupStatus.ACTIVE), [selectedGroups])
  const [associatedParentsMap, setAssociatedParentsMap] = useState<AssociatedParentsMap>(new Map<string, boolean>())
  const initialSelectedGroupRef = useRef(selectedGroups)
  const [hiddenSlidesVisible, setHiddenSlidesVisible] = useState<boolean>(true)
  const [showReviewRequiredOnly, setShowReviewRequiredOnly] = useState<boolean>(hasNeedReviewGroup)
  const [displayPageSourceFile, setDisplayPageSourceFile] = useState<boolean>(true);
  const [activeReplacementGroup, setActiveReplacementGroup] = useState<PayloadGroup | undefined>();
  const dispatch = useDispatch()
  const pendingGroupsRevision = useRef<boolean>(false);
  const [disablePreview, setDisablePreivew] = useState<boolean>(false);
  const [showUnavailableMessage, setShowUnavailableMessage] = useState<boolean>(false);
  const [allContentPageDataVersions, setAllContentPageDataVersions] =
    useState<{[documentVersionId: string] : ContentPageData[]}>({})

  const isSlideVersionTrackingEnabled = useFeatureFlags('BEAC_4251_slide_version_tracking')

  const currentVersionS3Mapping = useMemo(() => {
    return existingCustomDeck ? getS3KeysWithinCustomDeck(existingCustomDeck) : {}
  }, [existingCustomDeck])
  const latestVersionS3Mapping = useMemo(() => {
    return existingCustomDeck ? getS3KeysWithinCustomDeck(existingCustomDeck, true) : {}
  }, [existingCustomDeck])

  useEffect(() => {
    const getAllContentPageDataVersions = async () => {
      const responses = await fetchPagesJson({ ...currentVersionS3Mapping, ...latestVersionS3Mapping }, false)
      const mappingDocumentVersionIdtoContentPage =
        responses.reduce<{ [documentVersionId: string] : ContentPageData[]}>((acc, response) => {
          const documentVersionORM = getCurrentDocVer(response.documentVersionId)
          if (!documentVersionORM) {
            const errorMessage = 'Unable to find documentVerionORM from redux state'
            logger.customDeck.error(errorMessage)
            throw Error(errorMessage)
          }
          if (!acc[response.documentVersionId]) {
            const items = response.content.pages
              .map(page => {
                if (!page) {
                  logger.customDeck.warn('Page is null')
                  return null
                }
                const pageModel = documentVersionORM.relations.pages[page.number - 1]?.model
                if (!pageModel) {
                  logger.customDeck.verbose(`Page model not found for page number ${page.number}`)
                  return null
                }
                const rv: ContentPageData = {
                  content: page.content,
                  presentationPageNumber: page.number,
                  speakerNotes: page.overrideSpeakerNotes ?? page.speakerNotes,
                  s3Key: detectArchivedFileKeyPath(documentVersionORM.model, pageModel, 'sm') || '',
                  title: page.overrideTitle ?? page.title,
                  recommendations: page.recommendations ?? [],
                  mapping: isSlideVersionTrackingEnabled
                    ? (page.mapping ?? null)
                    : null,
                  pageId: pageModel.pageId,
                }

                return rv
              })
              .filter((page): page is ContentPageData => page !== null)

            acc[response.documentVersionId] = items
          }
          return acc
        }, {})

      setAllContentPageDataVersions(mappingDocumentVersionIdtoContentPage)
    }
    getAllContentPageDataVersions()
  }, [currentVersionS3Mapping, latestVersionS3Mapping])

  const visibleGroupsMap = useMemo(() => {
    return selectedGroups.reduce<Record<string, boolean>>((acc, group) => {
      const isUnavailable = !!unavailableStatus[group.groupStatus]
      const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE
      const isMinorWithAutoMappingSlides = group.groupStatus === GroupStatus.MINOR_UPDATE_WITH_AUTO_MAPPING_SLIDES
      const isSlideVisible = showReviewRequiredOnly
        ? (isUnavailable || isFindAndReplace || isMinorWithAutoMappingSlides)
        : (group.groupStatus !== GroupStatus.ACTIVE ||
          group.visible ||
          (hiddenSlidesVisible && !group.visible))

      acc[group.id] = isSlideVisible;
      return acc;
    }, {});
  }, [selectedGroups, hiddenSlidesVisible, showReviewRequiredOnly]);

  // When the visible group changed, we want to unselect everything
  useEffect(() => {
    setSelectedTargetItems([])
  }, [visibleGroupsMap])

  // We want to clear the number of required slide banner after 5 second
  useEffect(() => {
    if (numOfRequiredSlides !== 0) setTimeout(() => setNumOfRequiredSlides(0), 5000)
  }, [numOfRequiredSlides, setNumOfRequiredSlides])

  const {
    editorThumbnailSize,
    setEditorThumbnailSize,
    cycleEditorThumbnailSize,
    editorThumbnailDimensions,
  } = useEditorState(builderMode)

  const {
    selectorThumbnailSize,
    setSelectorThumbnailSize,
    cycleSelectorThumbnailSize,
    selectorThumbnailDimensions,
  } = useSelectorState()
  const currentUser = useCurrentUser()
  const currentUserId = currentUser.userProfile?.id

  const isCustomDeckLockedByOthers = useRef<boolean | undefined>(existingCustomDeck &&
    existingCustomDeck?.model.editMutex &&
    timeSinceLastSeen(existingCustomDeck.model.editMutex) < TIME_LAST_SEEN_MINUTES &&
    currentUserId !== existingCustomDeck.model.editMutex.userId).current
  /** Locking Mutex for Collaborative Editing */
  const intervalRef = useRef<NodeJS.Timeout>()

  const sendMutex = useCallback(() => {
    const timeStarted = new Date()
    existingCustomDeck && dispatch(customDeckActions.lockCustomDeck({
      customDeckId: existingCustomDeck.model.id!,
      timestarted: timeStarted.toISOString(),
      lock: true,
      currentUser: currentUser.userProfile!,
    }))
  }, [currentUser.userProfile?.id, existingCustomDeck])

  useEffect(() => {
    // We set readOnly to false when we see the deck is locked by the current user
    if (existingCustomDeck && existingCustomDeck.model.editMutex &&
      existingCustomDeck.model.editMutex.userId === currentUserId &&
      timeSinceLastSeen(existingCustomDeck.model.editMutex) < TIME_LAST_SEEN_MINUTES) {
      setIsLocked(false)
    }
  }, [existingCustomDeck])

  useEffect(() => {
    if (existingCustomDeck && !isCustomDeckLockedByOthers) {
      sendMutex();
      intervalRef.current = setInterval(sendMutex, MUTEX_INTERVAL)
      return () => {
        intervalRef.current && clearInterval(intervalRef.current)
      }
    }
  }, [])

  /**
   * when the selected groups collection is mutated, we need to update the associated selected target items
   */
  useEffect(() => {
    if (selectedTargetItems.length) {
      const validSelectedTargetItems = selectedTargetItems.filter((id) => {
        const page = selectedGroups.find((group) => group.id === id)
        return !!page
      },
      )
      setSelectedTargetItems(validSelectedTargetItems)
    }

    if (pendingGroupsRevision.current) {
      const allGroupsAreValid = selectedGroups.every((group) => group.groupStatus === GroupStatus.ACTIVE);
      if (allGroupsAreValid) {
        onApplyFindAndReplace(selectedGroups, title || '');
      }
    }
  }, [selectedGroups])

  /** CONTEXT UTILITIES */
  const handleModeChange = useCallback((nextMode?: BuilderMode) => {
    // from selection mode or replace mode user can only go to customization mode
    // from customization mode user can either go to selection or replace depending on the button clicked
    setBuilderMode((p) => {
      if (nextMode) {
        return nextMode
      } else if (p === 'selection' || p === 'replace') {
        return 'customization'
      } else {
        // this is a catch all where the user is sent to the default mode
        return 'selection'
      }
    })
  }, [setBuilderMode])

  const openFindReplacement = useCallback((group: ModifiedPayloadGroup) => {
    setFindReplacementVisible(true)
    setActiveReplacementGroup(group)
    handleModeChange('replace')
  }, [setFindReplacementVisible, setActiveReplacementGroup, handleModeChange])

  const clearSlides = () => {
    selectedGroups.length &&
      dispatch(
        DNAModalActions.setModal(
          {
            isVisible: true,
            allowBackdropCancel: true,
            component: (props) => (
              <ClearSlides
                {...props}
                onClear={() => setNumOfRequiredSlides(0)}
                setSelectedGroups={setSelectedGroups}
              />
            ),
          },
        ),
      )
  }

  const onPreview = (): void => {
    const draftFolderItemORM = toFolderItemORM(selectedGroups, title, existingCustomDeck, folderItemORM);

    // TODO: add type guard and remove as assertion here once typings cleanup from #1708 has been merged
    dispatch(
      contentPreviewModalActions.setModalVisibility({
        documentVersionId: (draftFolderItemORM.relations.itemORM.model as CustomDeck)
          .groups[0]?.pages[0].documentVersionId,
        folderItemORM: draftFolderItemORM,
        isOpen: true,
        content: draftFolderItemORM,
      },
      ))
  };

  const savePresentation = () => {
    if (selectedGroups.length) {
      if (!title) {
        setSaveAttemptedWithoutTitle(true);
        return;
      }
      const requiredHiddenSlides = selectedGroups.some((slide) => {
        return !slide.visible && slide.pages.some((page) => page.isRequired || associatedParentsMap.get(page.pageId))
      })

      if (requiredHiddenSlides) {
        openRequiredHiddenSlidesConfirmation()
        return
      }

      createOrUpdatePresentation()
      setSaveAttemptedWithoutTitle(false);
    }
  }

  // For this function we are only saving the slides (not title)
  const saveFindReplacement = useCallback((
    updatedPayloadGroups: PayloadGroup[],
    replaceGroupDraft: ReplaceGroupDraft[],
    callBack?: () => void,
  ) => {
    // Reset the initial value
    initialSelectedGroupRef.current = updatedPayloadGroups
    setSaveAttemptedWithoutTitle(false);
    if (!existingCustomDeck) {
      const errorText = 'Cannot find existing custom deck when trying to save replacement'
      logger.customDeck.updates.findAndReplace.error(errorText)
      throw new Error(errorText)
    }

    const isValidCustomDeck = folderItemORM && targetFolder

    if (!isValidCustomDeck) {
      const errorText = 'Cannot find folderItemORM or targetFolder when trying to save replacement'
      logger.customDeck.updates.findAndReplace.error(errorText)
      throw new Error(errorText)
    }

    const isCreatorOfDeck = existingCustomDeck.model.createdBy === currentUserId
    const isCollaborative = !!isSharedFolders || !isCreatorOfDeck
    const newCustomDeckGroup = payloadGroupsToCustomDeckGroup(updatedPayloadGroups, existingCustomDeck)

    if (isCollaborative) {
      try {
        dispatch(customDeckActions.updateCustomDeck({
          customDeck: existingCustomDeck.model,
          title: existingCustomDeck.model.title,
          groups: newCustomDeckGroup,
          folder: targetFolder,
          folderItem: folderItemORM,
          currentUser: currentUser.userProfile!,
        }))
      } catch (e) {
        const errorText = 'Error updating custom deck in collaborative mode'
        logger.customDeck.updates.findAndReplace.error(errorText)
        throw new Error(errorText)
      }
    } else {
      try {
        dispatch(folderActions.updateCustomDeck(
          targetFolder,
          folderItemORM,
          existingCustomDeck.model,
          newCustomDeckGroup,
          existingCustomDeck.model.title,
        ))
      } catch (e) {
        const errorText = 'Error updating custom deck in non-collaborative mode'
        logger.customDeck.updates.findAndReplace.error(errorText)
        throw new Error(errorText)
      }
    }

    // Update userNotation
    const userNotations = existingCustomDeck.relations.userNotations
    const notations = userNotations?.notation
    if (notations) {
      const pageIdMap = notations.reduce<{[oldPageId: string]: string}>((acc, curr) => {
        const oldPageId = curr.pageId
        const matchingPageGroupDraft = replaceGroupDraft.find(group => {
          return group.status === GroupDraftStatus.REPLACED && group.pages.find(page => page.pageId === oldPageId)
        })
        if (matchingPageGroupDraft) {
          const newPageId = matchingPageGroupDraft.groupReplacement?.pages[0].pageId
          if (newPageId && !acc[oldPageId]) acc[oldPageId] = newPageId
        }
        return acc
      }, {})

      !isEmpty(pageIdMap) && dispatch(userNotationsActions.updateNotationsPageId({
        userNotations,
        pageIdMap,
      }))
    }

    callBack?.()
  }, [existingCustomDeck])

  const createOrUpdatePresentation = () => {
    if (existingCustomDeck) {
      const isOwnCustomDeck = existingCustomDeck.model.createdBy === currentUser.userProfile?.id
      folderItemORM &&
      targetFolder &&
      title &&
      !isSharedFolders && isOwnCustomDeck &&
      dispatch(folderActions.updateCustomDeck(
        targetFolder,
        folderItemORM,
        existingCustomDeck.model,
        payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
        title,
      ))

      // collaboration mode
      folderItemORM &&
      targetFolder &&
      title &&
      (isSharedFolders || !isOwnCustomDeck) &&
      dispatch(customDeckActions.updateCustomDeck({
        customDeck: existingCustomDeck.model,
        title: title,
        groups: payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
        folder: targetFolder,
        folderItem: folderItemORM,
        currentUser: currentUser.userProfile!,
      },
      ))
      const shouldUpdateFolderItem = targetFolder && folderItemORM && existingCustomDeck.model.title !== title
      shouldUpdateFolderItem && dispatch(sharedFolderActions.updateItems({
        folder: targetFolder,
        action: UpdateType.UPDATE_FOLDER_ITEM_TITLE,
        folderItems: [{ ...folderItemORM, model: { ...folderItemORM.model, customTitle: title } }],
      }))
      closePresentationBuilder()
    } else {
      if (!targetFolder) {
        const customDeck = payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck)
        title && dispatch(DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (
            <SelectFolder
              {...props}
              itemORM={customDeck}
              title={title}
              onDone={closePresentationBuilder}
            />
          ),
        }),
        )
      }
      else {
        const isSharedFolder = isSharedEditableFolder(targetFolder)
        if (!title) return
        if (!isSharedFolder) {
          dispatch(folderActions.createCustomDeck(
            targetFolder,
            payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck), title))
        }
        else {
          dispatch(sharedFolderActions.createCustomDeckInFolder(
            {
              targetFolder,
              selectedSlides: payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
              title : title || '',
            },
          ))
        }
        closePresentationBuilder()
      }
    }
  }

  const cancelPresentation = () => {
    const hasChanges = !isEqual(initialSelectedGroupRef.current, selectedGroups) || initialTitleRef.current !== title
    if (hasChanges) {
      openCloseConfirmation()
      return
    }

    customDeckId &&
      !isCustomDeckLockedByOthers && dispatch(customDeckActions.lockCustomDeck({
      customDeckId: customDeckId,
      lock: false,
      timestarted: '',
      currentUser: currentUser.userProfile!,
    }))
    closePresentationBuilder()
  }

  const closePresentationBuilder = () => {
    dispatch(presentationBuilderActions.closePresentationBuilder())
  }

  const openRequiredHiddenSlidesConfirmation = () => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (
            <RequiredSlidesHiddenConfirmation
              onSave={createOrUpdatePresentation}
              {...props}
            />
          ),
        },
      ),
    )
  }

  const onApplyFindAndReplace = (payloadGroups: PayloadGroup[], newTitle?: string) => {
    newTitle && setTitle(newTitle);
    setBuilderMode('customization')
    const { groups, numRequiredSlidesAdded } = validatePayloadGroups(payloadGroups)

    const allAssociatedParents: {[key: string]: string[]} = {}
    groups.forEach(group => {
      group.documentVersionORM?.meta.allPages.forEach(page => {
        page.linkedSlides?.forEach(slideId => {
          if (allAssociatedParents[slideId] && !allAssociatedParents[slideId].includes(page.pageId)) {
            allAssociatedParents[slideId].push(page.pageId)
          }
          else allAssociatedParents[slideId] = [page.pageId]
        })
      })
    })
    const modifiedPayloadGroups:ExtendedPagePayloadGroup[] = groups.map(group => {
      const extendedPages:ExtendedPage[] = group.pages.map(page => {
        const extenedPage:ExtendedPage = {
          ...page,
          parentIds: allAssociatedParents[page.pageId],
        }
        return extenedPage
      })
      const modifiedPayloadGroup:ExtendedPagePayloadGroup = { ...group, pages: extendedPages }
      return modifiedPayloadGroup
    })
    setSelectedGroups(modifiedPayloadGroups)
    setNumOfRequiredSlides(numRequiredSlidesAdded)
    return modifiedPayloadGroups
  };

  const validatePayloadGroups = (
    existingPayloadGroups: PayloadGroup[],
  ): {
    groups: PayloadGroup[],
    numRequiredSlidesAdded: number
  } => {
    const docVersionMap = new Map<string, DocumentVersionORM>();
    const addedPages = new Set<string>();
    const addedPageGroups = new Set<string>();
    let numAddedSlides = 0;

    // We only add required slides if the deck is in a valid state otherwise we let the user keep on editing
    if (!existingPayloadGroups.every((group) => group.groupStatus === GroupStatus.ACTIVE)) {
      // IF THERE ARE ASSOCIATED SLIDES, THE GROUPS NEED TO BE ADDED TO A QUEUE FOR LATER REVIEW
      existingPayloadGroups.forEach((group) => {
        group.pages.forEach((page) => {
          if (page.linkedSlides?.length) {
            pendingGroupsRevision.current = true;
          }
        });
      });

      return {
        groups: existingPayloadGroups,
        numRequiredSlidesAdded: 0,
      }
    } else {
      pendingGroupsRevision.current = false;
    }

    existingPayloadGroups.forEach(group => {
      if (group.documentVersionORM) {
        docVersionMap.set(group.documentVersionORM!.model.id, group.documentVersionORM!)
        group.pages.forEach(page => addedPages.add(page.pageId))
        group.groupSrcId && addedPageGroups.add(group.groupSrcId)
      }
    })

    const newPayloadGroups: PayloadGroup[] = []

    // We iterate through all documents making sure required group/slides are already added
    // Since now the required slide are added through selection stage, this can be the reassurance
    docVersionMap.forEach((docVer) => {
      const {
        relations: {
          documentORM, pages,
        },
        model:{
          id,
        },
      } = docVer

      pages.forEach(page => {
        const { model:{ isRequired }, relations:{ pageGroupORM } } = page
        /** Identify required pages... */
        if (isRequired) {
          /** ... with related page groups that are not currently in the addedPageGroups set and add them to
           * the newPayloadGroups array. Additionally, add the source doc group id to the addedPageGroups set */
          if (pageGroupORM && !addedPageGroups.has(pageGroupORM.model.id)) {
            // const srcDocGroup = pageGroupORM
            /**
             * Map the page models in the group's related PageORM array to a Page array
             * Additionally add each page id to the addedPages set
             * */
            const pages = pageGroupORM.relations.pages.map(({ model }) => {
              addedPages.add(model.pageId)
              return model;
            })
            newPayloadGroups.push({
              id: uuid(),
              groupSrcId: pageGroupORM.model.id,
              documentVersionORM: docVer,
              pages,
              visible: true,
              groupStatus: GroupStatus.ACTIVE,
              docAccessLevel: pageGroupORM.relations.documentVersionORM.relations.documentORM.model.accessLevel,
              locked: pageGroupORM.model.locked,
            })
            numAddedSlides = numAddedSlides + pages.length
            addedPageGroups.add(pageGroupORM.model.id)

          /** ... which are not in the addedPages set and add them to the newPayloadGroups array */
          } else if (!addedPages.has(page.model.pageId)) {
            addedPages.add(page.model.pageId)
            newPayloadGroups.push({
              id: uuid(),
              groupSrcId: id,
              documentVersionORM: docVer,
              pages: [page.model],
              visible: true,
              groupStatus: GroupStatus.ACTIVE,
              docAccessLevel: documentORM.model.accessLevel,
            })
            numAddedSlides++
          }
        }
      })
    })

    // Now we iterate through all slides and add associated slides
    const associatedPayloadGroups:PayloadGroup[] = []
    const associatedSlideIds = new Set<string>();
    const allPayloadGroups = [...existingPayloadGroups, ...newPayloadGroups]

    // Iterate over all payload groups and add the associated slides to associatedSlides set
    allPayloadGroups.forEach(({ documentVersionORM, pages, docAccessLevel }) => {
      pages.forEach(page => {
        page.linkedSlides?.forEach(slideId => {
          // If linkedSlide is not already contained in payloadGroups and we have not add it yet
          const existsInPayloadGroups = !existingPayloadGroups.find(group =>
            group.pages.some(page => page.pageId === slideId))
          if (!associatedSlideIds.has(slideId) && existsInPayloadGroups) {
            const associatedSlide = documentVersionORM?.meta.allPages.find(page => page.pageId === slideId)
            if (associatedSlide) {
              // then we will push the association slide in associated array
              associatedSlideIds.add(slideId)
              const newAssociatedSlide: PayloadGroup = {
                id: uuid(),
                groupSrcId: slideId,
                documentVersionORM: documentVersionORM,
                pages: [associatedSlide],
                visible: true,
                groupStatus: GroupStatus.ACTIVE,
                docAccessLevel: docAccessLevel,
              }
              associatedPayloadGroups.push(newAssociatedSlide)
              numAddedSlides++
            }
          }
        })
      })
    })

    // Sort all required slides in slide number order
    const sortedByPageNum = [...newPayloadGroups, ...associatedPayloadGroups]
      .sort((a, b) => (a.pages[0].number < b.pages[0].number) ? -1 : 1)
    return {
      groups: [...existingPayloadGroups, ...sortedByPageNum],
      numRequiredSlidesAdded: numAddedSlides,
    }
  }

  const onCloseHandler = () => {
    batch(() => {
      customDeckId && dispatch(customDeckActions.lockCustomDeck({
        customDeckId: customDeckId,
        lock: false,
        timestarted: '',
        currentUser: currentUser.userProfile!,
      }))
      dispatch(presentationBuilderActions.closePresentationBuilder())
    })
  }

  const openCloseConfirmation = () => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => <CloseConfirmation {...props} onClose={onCloseHandler} />,
        },
      ),
    )
  }

  const onRemoveGroup = useCallback((group: PayloadGroup) => {
    group.pages.forEach((page) => {
      analytics?.track('CUSTOM_SLIDE_REMOVED', {
        action: 'SLIDE_REMOVED',
        category: 'CUSTOM',
        customDeckId: existingCustomDeck?.model.id,
        groupId: group.id,
        pageId: page.pageId,
        editorType,
      });
    })
    setSelectedGroups((groups) => groups.filter((grp) => grp.id !== group.id))
  }, [editorType, existingCustomDeck, setSelectedGroups])

  const onRemoveAllUnavailableGroups = useCallback(() => {
    setSelectedGroups((groups) => {
      return groups.filter((grp) => {
        const isUnavailable = !!unavailableStatus[grp.groupStatus]
        if (isUnavailable) {
          grp.pages.forEach((page) => {
            analytics?.track('CUSTOM_SLIDE_REMOVED', {
              action: 'SLIDE_REMOVED',
              category: 'CUSTOM',
              customDeckId: existingCustomDeck?.model.id,
              groupId: grp.id,
              pageId: page.pageId,
              editorType: editorType,
            });
          })
        }
        return !isUnavailable
      })
    })
  }, [editorType, existingCustomDeck, setSelectedGroups])

  const onSeparateGroup = useCallback((group: PayloadGroup) => {
    /** Filter out current group from selected groups */
    const updatedSelectedGroups = selectedGroups.filter(({ id }) => group.id !== id)

    /** Add slides from current group as individual groups */
    const separatedSlides = group.pages.map(page => {
      const newPayloadGroup: PayloadGroup = {
        id: uuid(),
        pages: [page],
        visible:true,
        groupStatus: GroupStatus.ACTIVE,
        docAccessLevel: group.docAccessLevel,
        documentVersionORM: group.documentVersionORM,
      }
      return newPayloadGroup
    })

    updatedSelectedGroups.push(...separatedSlides)

    setSelectedGroups(updatedSelectedGroups)

    // ANALYTIC TRACKING: ungrouped named group
    analytics?.track('GROUP_UNGROUP', {
      customDeckId: existingCustomDeck?.model.id,
      srcGroupId: group.id,
    })
  }, [selectedGroups, setSelectedGroups, existingCustomDeck])

  /**
 * This function check if this group has association to determin if the remove button should show or not
 * If page within group has associated parent(s) and there is no duplicate of page, then user cannot remove the group
 */
  const isSingleAndHasAssociation = useCallback((group: ModifiedPayloadGroup):boolean => {
    let canRemove = true
    group.pages.forEach(page => {
      if (associatedParentsMap.get(page.pageId)) {
        let count = 0
        selectedGroups.forEach(group => {
          group.pages.forEach(p => {
            if (p.pageId === page.pageId) count++
          })
        })
        if (count < 2) canRemove = false
      }
    })
    return !canRemove
  }, [selectedGroups, associatedParentsMap])

  const canRemoveGroup = useCallback((group: PayloadGroup): boolean => {
    const pageIdRequiredCountMap = group.documentVersionORM?.meta.allPages.reduce<Record<string, number>>(
      (acc, page) => {
        if (page.isRequired) {
          const selectedRequiredCount = selectedGroups.reduce<number>(
            (acc, grp) => {
              if (grp.documentVersionORM === group.documentVersionORM) {
                grp.pages.forEach(pg => {
                  if (pg.isRequired && pg.pageId === page.pageId)
                  { acc++ }
                })
              }

              return acc
            },
            0,
          )

          acc[page.pageId] = selectedRequiredCount
        }
        return acc
      },
      { },
    ) ?? { }

    const canRemove = group.pages.every(page => {
      const requiredCount = pageIdRequiredCountMap[page.pageId]

      return requiredCount !== undefined
        ? requiredCount > 1
        : true
    })

    return canRemove
  }, [selectedGroups])

  const autoUpdateMapping = useMemo(() => {
    return selectedGroups.reduce<Record<string, string>>((acc, group) => {
      const isSingleSlideGroup = group.pages.length === 1;
      if (isSingleSlideGroup) {
        const documentORM = group.documentVersionORM?.relations.documentORM
        const latestDocumentVersionId = documentORM?.relations.version.latestPublishedDocumentVersionORM?.model.id;
        const latestVersionContentPageDatas = latestDocumentVersionId
          ? allContentPageDataVersions[latestDocumentVersionId]
          : undefined;
        const newReplacementPageId = latestVersionContentPageDatas?.find(pageData => {
          return pageData.mapping === group.pages[0].pageId;
        })?.pageId
        if (newReplacementPageId) return { ...acc, [group.pages[0].pageId]: newReplacementPageId }
      }
      return acc
    }, {})
  }, [selectedGroups, allContentPageDataVersions])

  // DETERMINES WHICH GROUPS NEED TO CHANGE THEIR STATUS TO "MINOR_UPDATE_WITH_AUTO_MAPPING_SLIDES"
  // TO DO SO, THE autoUpdateMapping MUST BE SET TO CHECK THE "PRE-MAPPED" SLIDES.
  useEffect(() => {
    if (Object.keys(autoUpdateMapping).length && selectedGroups.length) {
      let hasAGroupStatusChanged = false;
      const updatedGroups = selectedGroups.map((group) => {
        if (group.groupStatus === GroupStatus.ACTIVE) {
          const hasAutoMappingSlides =
            group.pages.some(({ pageId }) => !!autoUpdateMapping[pageId]);

          if (hasAutoMappingSlides) {
            hasAGroupStatusChanged = true;
            return {
              ...group,
              groupStatus: GroupStatus.MINOR_UPDATE_WITH_AUTO_MAPPING_SLIDES,
            };
          }
        }
        return group;
      });

      hasAGroupStatusChanged && setSelectedGroups(updatedGroups);
    }
  }, [autoUpdateMapping]);

  const acceptAutoUpdateSlides = useCallback(() => {
    const updatedGroups: PayloadGroup[] = [];
    const replaceGroups = assignReplacementGroups(selectedGroups, true)

    replaceGroups.forEach((replaceGroup) => {
      if (replaceGroup.status !== GroupDraftStatus.GROUP_REMOVED) {
        // currently we do not support mapping for groups with multiple pages
        const currentGroup = selectedGroups.find(({ id }) => replaceGroup.groupId === id)!;
        if (replaceGroup.pages.length === 1) {
          const latestPublishedDocumentVersionORM =
            currentGroup.documentVersionORM?.relations.documentORM.relations.version.latestPublishedDocumentVersionORM
          const newReplacementPageId = autoUpdateMapping[replaceGroup.pages[0].pageId]
          const newReplacementPage = latestPublishedDocumentVersionORM?.relations.pages.find(page => {
            return page.model.pageId === newReplacementPageId
          })?.model

          if (newReplacementPageId && newReplacementPage) {
            const replacementGroup = {
              id: uuid(),
              groupSrcId: newReplacementPage?.pageId,
              documentVersionORM: latestPublishedDocumentVersionORM,
              pages: [newReplacementPage],
              visible: true,
              groupStatus: GroupStatus.ACTIVE,
              docAccessLevel: latestPublishedDocumentVersionORM.relations.documentORM.model.accessLevel,
            }
            updatedGroups.push(replacementGroup)
            replaceGroup.groupReplacement = replacementGroup
            replaceGroup.status = GroupDraftStatus.REPLACED
          } else {
            updatedGroups.push(currentGroup)
          }
        } else {
          updatedGroups.push(currentGroup)
        }
      }
    });
    const updatedPayloadGroups = onApplyFindAndReplace(updatedGroups);
    saveFindReplacement(updatedPayloadGroups, replaceGroups)
    analytics?.track('CUSTOM_REPLACE_ACCEPT_ALL', {
      action: 'REPLACE',
      category: 'CUSTOM',
      customDeckId: existingCustomDeck?.model.id,
    });
  }, [selectedGroups, allContentPageDataVersions, autoUpdateMapping, existingCustomDeck])

  const contextValue: PresentationBuilderStateType = {
    onApplyFindAndReplace,
    clearSlides,
    closePresentationBuilder,
    savePresentation,
    saveFindReplacement,
    builderMode,
    setBuilderMode,
    title,
    setTitle,
    saveAttemptedWithoutTitle,
    setSaveAttemptedWithoutTitle,
    numOfRequiredSlides,
    setNumOfRequiredSlides,
    selectedGroups,
    setSelectedGroups,
    visibleGroupsMap,
    associatedParentsMap,
    setAssociatedParentsMap,
    hiddenSlidesVisible,
    setHiddenSlidesVisible,
    showReviewRequiredOnly,
    setShowReviewRequiredOnly,
    displayPageSourceFile,
    setDisplayPageSourceFile,
    handleModeChange,

    onPreview,
    editorThumbnailSize,
    setEditorThumbnailSize,
    cycleEditorThumbnailSize,
    editorThumbnailDimensions,

    selectorThumbnailSize,
    setSelectorThumbnailSize,
    cycleSelectorThumbnailSize,
    selectorThumbnailDimensions,

    cancelPresentation,
    activeReplacementGroup,
    setActiveReplacementGroup,
    customDeck: existingCustomDeck,
    editorType,
    variant,
    isLocked,
    isCustomDeckLockedByOthers,

    selectedTargetItems,
    setSelectedTargetItems,
    disablePreview,
    setDisablePreivew,
    showUnavailableMessage,
    setShowUnavailableMessage,
    findReplacementVisible,
    setFindReplacementVisible,
    openFindReplacement,
    hasNeedReviewGroup,

    onRemoveGroup,
    onRemoveAllUnavailableGroups,
    onSeparateGroup,
    isSingleAndHasAssociation,
    canRemoveGroup,

    targetFolder,

    allContentPageDataVersions,
    acceptAutoUpdateSlides,
    autoUpdateMapping,
  }

  return (
    <PresentationBuilderStateContext.Provider value={contextValue}>
      {props.children}
    </PresentationBuilderStateContext.Provider>
  )
}

PresentationBuilderStateProvider.displayName = 'PresentationBuilderStateProvider'

/** Hook for utilizing presentation builder state values/methods. Also
 * handles guarding against using context outside of the provider */
export function usePresentationBuilderState() {
  const context = useContext(PresentationBuilderStateContext)
  if (!context) {
    throw new Error('usePresentationBuilderState must be used within the PresentationBuilderStateProvider')
  }
  return context;
}

export default PresentationBuilderStateProvider
