import React, { useMemo } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import useSelector, { composite } from 'src/hooks/useSelector'
import * as shareMachine from 'src/state/machines/sharing/shareMachine.selectors'
import ShareMachineProvider, { useShareMachine } from 'src/state/machines/sharing/shareMachine.provider'
import { ComposableField } from 'src/components/CustomFields/ComposableField'
import {
  DNABox,
  DNAButton,
  DNACheckbox,
  DNAChip,
  DNAIcon,
  DNAModal,
  DNAText,
  Iffy,
  RadioGroup,
  RadioItem,
  DNAConditionalWrapper,
  DNADivider,
} from '@alucio/lux-ui'
import { canPerformAction, useDocumentVersionORM } from 'src/state/redux/selector/document'
import {
  AssociatedFileORM,
  ContentShareORM,
  DOCUMENT_ACTIONS_ENUM,
  DocumentVersionORM,
  EmailTemplateORM,
  getAssociatedFileTitle,
} from 'src/types/types'
import { useTenant, useTenantCustomFields } from 'src/state/redux/selector/tenant'
import { AssociatedFileType, CustomFieldUsage, ShareNotificationScope } from '@alucio/aws-beacon-amplify/src/models'
import CollapsibleCard from 'src/components/CollapsibleCard/CollapsibleCard'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors'
import {
  CopyEmailButtonProps,
  CopyLinkButtonProps,
  OpenEmailButtonProps,
  formatShareableFiles,
  isEntitySelectedToShare,
} from 'src/state/machines/sharing/shareUtils'
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate'
import { DNAModalVariant } from '../DNAConnectedModal'
import { ComposableForm } from 'src/components/CustomFields/ComposableForm'
import { getMappedCustomValues } from 'src/state/redux/selector/common'
import ActiveUser from 'src/state/global/ActiveUser'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'

export enum ShareVariantOptions {
  DOC_VER_SHARE = 'DOC_VER_SHARE',
  HUB_SHARE = 'HUB_SHARE',
  CUSTOM_DECK_SHARE = 'CUSTOM_DECK_SHARE',
}

export type ShareVariantType = keyof typeof ShareVariantOptions

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  modal: {
    maxHeight: window.innerHeight - 64,
  },
  modalBody: {
    backgroundColor: colors['color-gray-20'],
  },
  modalContainer: {
    width: 800,
    maxHeight: window.innerHeight - 224, // 224 becuase 64 for modal margin within the innerHeight, then 56 * 2 for the header and footer and then 24 * 2 for the top and bottom margin
    // better way to do the maxHeight?
    paddingBottom: 24,
  },
  commonBorder: {
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
    borderRadius: 6,
  },
  documentList: {
    paddingLeft: 16,
    paddingVertical: 12,
  },
  selectAll: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    width: 123,
    marginVertical: 24,
  },
  associatedFilesRowContainer: {
    overflow: 'hidden',
  },
  associatedFilesRowBody: {
    borderBottomColor: colors['color-gray-100'],
    borderBottomWidth: 1,
    padding: 12,
    backgroundColor: colors['color-gray-20'],
  },
  sectionTitle: {
    color: colors['color-text-tertiary'],
  },
  sectionBody: {
    padding: 24,
    backgroundColor: colors['color-text-white'],
    marginTop: 24,
    marginHorizontal: 24,
  },
  tooltipIcon: {
    color: '#B6B6B6',
    width: 20,
    height: 20,
  },
  documentChip: {
    minWidth: 45,
    marginTop: 8,
  },
  radioButton: {
    margin: 16,
    paddingRight: 32,
    flex: 1,
    width: '100%',
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-100'],
  },
});

type AssociatedFilesSelectionRow = {
  docVerORMId: string,
  multipleDocShare?: boolean,
}

export const shareVariantConfig = {
  [ShareVariantOptions.DOC_VER_SHARE]: {
    customFieldUsage: CustomFieldUsage.CONTENT_SHARE,
  },
  [ShareVariantOptions.CUSTOM_DECK_SHARE]: {
    customFieldUsage: CustomFieldUsage.CONTENT_SHARE,
  },
  [ShareVariantOptions.HUB_SHARE]: {
    customFieldUsage: CustomFieldUsage.HUB,
  },
}

export const ShareAssociatedFileRow: React.FC<{
  associatedFile: AssociatedFileORM
  onToggleCheckbox: (mainDocVerId: string, entityORM: AssociatedFileORM | DocumentVersionORM) => void
  isEntitySelectedToShareWrapper: (
    mainDocVerId: string,
    targetedEntityId: string,
  ) => boolean | undefined
  mainDocVerId: string,
}> = props => {
  const {
    associatedFile,
    onToggleCheckbox,
    isEntitySelectedToShareWrapper,
    mainDocVerId,
  } = props;
  const targetedEntityId = associatedFile.model.type === AssociatedFileType.ATTACHED_FILE
    ? associatedFile.file?.id
    : associatedFile.relations.latestUsableDocumentVersion?.id!

  return (
    <DNABox key={associatedFile.model.id}>
      <DNABox appearance="row" spacing="md" alignY="center" fill childStyle={[2, { flex: 1 }]}>
        <DNACheckbox
          checked={isEntitySelectedToShareWrapper(mainDocVerId, targetedEntityId!)}
          disabled={associatedFile.model.isDefault}
          status="primary"
          appearance="default"
          onChange={() => onToggleCheckbox(mainDocVerId, associatedFile)}
        >
          {/* UI Kitten requires a child text to be passed as a func */}
          {() => (
            <DNABox appearance="col" alignY="center" style={{ paddingLeft: 12 }}>
              <DNAText numberOfLines={1} style={{ width: 600 }}>
                {getAssociatedFileTitle(associatedFile)?.trim()}
              </DNAText>
              <DNABox style={styles.documentChip}>
                <DNAChip appearance="tag">
                  {associatedFile.file?.type}
                </DNAChip>
              </DNABox>
            </DNABox>
          )}
        </DNACheckbox>
      </DNABox>
    </DNABox>
  )
}

const AssociatedFilesSectionRow: React.FC<AssociatedFilesSelectionRow> = (props) => {
  const { docVerORMId, multipleDocShare } = props
  const documentVersionORM = useDocumentVersionORM(docVerORMId)
  const { service } = useShareMachine()
  const tenant = useTenant(ActiveUser.user!.tenantId)
  const cond = useSelector(
    service,
    (state) => composite(
      state,
      shareMachine.shareVariant,
      shareMachine.entityShareIds,
      shareMachine.availableContentToShare,
    ),
  )

  if (!documentVersionORM) {
    return <></>
  }

  const handleToggleCheckbox = (mainDocVerId: string, entityORM: AssociatedFileORM | DocumentVersionORM) => {
    service.send({
      type: 'UPDATE_ASSOCIATED_FILES',
      ids: { mainDocVerId, entityORM },
    })
  }

  const mainDocVerId = docVerORMId
  const canMainFileBeShared = tenant
    ? canPerformAction(
      DOCUMENT_ACTIONS_ENUM.share,
      documentVersionORM.meta.customValues.configsMap,
      tenant.tenant,
    )
    : false

  const isEntitySelectedToShareWrapper = (mainDocVerId: string, targetedEntityId: string) => {
    const availableContentToShare = cond.availableContentToShare
    return isEntitySelectedToShare(mainDocVerId, targetedEntityId, availableContentToShare)
  }

  const formattedShareableFiles = formatShareableFiles(documentVersionORM)
  const isMainFileShare = isEntitySelectedToShareWrapper(mainDocVerId, mainDocVerId)
  const requiredDocLength = formattedShareableFiles.required.length
  const optionalDocLength = formattedShareableFiles.optional.length

  return (
    <DNAConditionalWrapper
      condition={multipleDocShare}
      wrapper={children => (
        <CollapsibleCard
          headerTitle={
            <DNABox fill spacing="between" alignY="center" style={{ marginLeft: 5 }}>
              <DNAText bold numberOfLines={2} style={{ width: 580 }}>
                {documentVersionORM.model.title?.trim()}
              </DNAText>
            </DNABox>
          }
          onToggleChanged={(collapsed) => !collapsed}
          isCollapsed={false}
        >{children}</CollapsibleCard>
      )}
    >
      <DNABox appearance="col" spacing="md" testID="associated-files-share-modal">

        {/* MAIN FILE */}
        <DNABox
          appearance="col"
          spacing="xs"
          alignY="center"
          fill
          style={[styles.associatedFilesRowContainer, styles.commonBorder]}
        >
          <DNABox alignY="center" spacing="sm" style={styles.associatedFilesRowBody}>
            <DNAText bold p1 style={styles.sectionTitle}>Main</DNAText>
            <DNAPopover placement="top" >
              <DNAPopover.Anchor>
                <DNAIcon name="help-circle-outline" status="tertiary" size="lg" style={styles.tooltipIcon} />
              </DNAPopover.Anchor>
              <DNAPopover.Content offset={2}>
                <DNAText status="basic">
                  This is the active file that you selected before opening this modal.
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
          </DNABox>
          <DNABox
            appearance="row"
            spacing="md"
            alignY="center"
            fill
            style={styles.documentList}
            childStyle={[2, { flex: 1 }]}
          >
            <DNACheckbox
              disabled={!canMainFileBeShared}
              checked={isMainFileShare}
              status="primary"
              appearance="default"
              onChange={() => handleToggleCheckbox(mainDocVerId, documentVersionORM)}
            >
              {/* UI Kitten requires a child text to be passed as a func */}
              {() => (
                <DNABox appearance="col" alignY="center" style={{ paddingLeft: 12 }}>
                  <DNAText style={{ flex: 1, fontSize: 14, lineHeight: 16, width: 600 }} numberOfLines={5} >
                    {documentVersionORM.model.title?.trim()}
                  </DNAText>
                  <DNABox style={styles.documentChip} spacing="sm">
                    <DNADocumentChip
                      item={documentVersionORM.relations.documentORM}
                      variant="docType"
                    />
                    <Iffy is={!canMainFileBeShared}>
                      <DNAChip status="primary">
                        {'Non-distributable'}
                      </DNAChip>
                    </Iffy>
                  </DNABox>
                </DNABox>
              )}
            </DNACheckbox>
          </DNABox>
        </DNABox>

        {/* REQUIRED FILES */}
        <Iffy is={requiredDocLength}>
          <DNABox
            appearance="col"
            spacing="xs"
            alignY="center"
            fill
            style={[styles.associatedFilesRowContainer, styles.commonBorder]}
          >
            <DNABox alignY="center" spacing="sm" style={styles.associatedFilesRowBody}>
              <DNAText bold p1 style={styles.sectionTitle}>Required</DNAText>
              <DNAPopover placement="top" >
                <DNAPopover.Anchor>
                  <DNAIcon name="help-circle-outline" status="tertiary" size="lg" style={styles.tooltipIcon} />
                </DNAPopover.Anchor>
                <DNAPopover.Content offset={2}>
                  <DNAText status="basic">
                    You are required to share these files when distributing externally.
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </DNABox>
            <DNABox appearance="col" spacing="md" style={styles.documentList}>
              {formattedShareableFiles.required.map((associatedFile, idx) =>
                (
                  <React.Fragment key={associatedFile.model.id}>
                    <ShareAssociatedFileRow
                      key={associatedFile.model.id}
                      associatedFile={associatedFile}
                      onToggleCheckbox={handleToggleCheckbox}
                      isEntitySelectedToShareWrapper={isEntitySelectedToShareWrapper}
                      mainDocVerId={mainDocVerId}
                    />
                    {formattedShareableFiles.required.length - 1 !== idx &&
                      <DNADivider style={{ marginLeft: -16, marginTop: 12 }} />}
                  </React.Fragment>
                ),
              )}
            </DNABox>
          </DNABox>
        </Iffy>

        {/* OPTIONAL FILES */}
        <Iffy is={optionalDocLength}>
          <DNABox
            appearance="col"
            spacing="xs"
            alignY="center"
            fill
            style={[styles.associatedFilesRowContainer, styles.commonBorder]}
          >
            <DNABox alignY="center" spacing="sm" style={styles.associatedFilesRowBody}>
              <DNAText bold p1 style={styles.sectionTitle}>Optional</DNAText>
              <DNAPopover placement="top" >
                <DNAPopover.Anchor>
                  <DNAIcon name="help-circle-outline" status="tertiary" size="lg" style={styles.tooltipIcon} />
                </DNAPopover.Anchor>
                <DNAPopover.Content offset={2}>
                  <DNAText status="basic">
                    These files are optional for you to share when distributing externally.
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </DNABox>
            <DNABox appearance="col" spacing="md" style={styles.documentList}>
              {formattedShareableFiles.optional.map((associatedFile, idx) =>
                (
                  <React.Fragment key={associatedFile.model.id}>
                    <ShareAssociatedFileRow
                      associatedFile={associatedFile}
                      onToggleCheckbox={handleToggleCheckbox}
                      isEntitySelectedToShareWrapper={isEntitySelectedToShareWrapper}
                      mainDocVerId={mainDocVerId}
                    />
                    {formattedShareableFiles.optional.length - 1 !== idx &&
                      <DNADivider style={{ marginLeft: -16, marginTop: 12 }} />}
                  </React.Fragment>),
              )}
            </DNABox>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNAConditionalWrapper>
  )
}

const AssociatedFilesSection: React.FC = () => {
  const { service } = useShareMachine()

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      shareMachine.shareVariant,
      shareMachine.entityShareIds,
      shareMachine.isAllSelected,
      shareMachine.availableContentToShare,
    ),
  )

  const handleSelectAll = () => {
    service.send({ type: 'TOGGLE_SELECT_ALL' })
  }

  let numOfSelected = 0
  if (cond.availableContentToShare) {
    Object.values(cond.availableContentToShare).flat(1).forEach(sfo => {
      if (sfo.beingShared) numOfSelected++
    })
  }

  // either one document or many documents selected to share
  return (
    <>
      {/* SELECT ALL CHECKBOX */}
      <DNABox appearance="row" spacing="between">
        <DNACheckbox
          checked={cond.isAllSelected}
          status="primary"
          appearance="default"
          testID="select-all"
          onChange={handleSelectAll}
          style={[styles.selectAll, styles.commonBorder]}
        >
          {/* UI Kitten requires a child text to be passed as a func */}
          {() => <DNAText style={{ marginLeft: 8 }}>Select all</DNAText>}
        </DNACheckbox>
        <DNABox appearance="row" spacing="sm" style={{ marginTop: 32 }}>
          <DNAText status="subtle">{`${numOfSelected} selected`}</DNAText>
        </DNABox>
      </DNABox>
      {cond.entityShareIds && cond.entityShareIds?.map(shareId => (
        <DNABox appearance="col" style={{ marginVertical: 12 }} key={shareId}>
          <AssociatedFilesSectionRow
            key={shareId}
            docVerORMId={shareId}
            multipleDocShare={cond.entityShareIds && cond.entityShareIds.length > 1}
          />
        </DNABox>
      ),
      )}
    </>
  )
}

interface EmailTemplateRowProps {
  emailTemplateORM: EmailTemplateORM,
}

const ContentShareModalBody: React.FC = () => {
  const { service, validateAndSubmit, closeModal, rhForm } = useShareMachine()
  const emailTemplates = useEmailTemplateListForMSL()

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      shareMachine.shareVariant,
      shareMachine.entityShareIds,
      shareMachine.selectedEmailTemplate,
      shareMachine.existingContentShareORM,
    ),
  )

  const customFieldsConfig = useMemo(
    () => ({ usages: { internalUsages: [shareVariantConfig[cond.shareVariant].customFieldUsage] } }),
    [cond.shareVariant],
  )

  const customFields = useTenantCustomFields(customFieldsConfig)
  const customValues = cond.existingContentShareORM?.model.customValues

  const isRequiredFieldDirty = !rhForm?.formState.isDirty
  const isEmailTemplateSectionDisabled = isRequiredFieldDirty && !cond.existingContentShareORM

  const memoizedCustomFields = useMemo(
    () => {
      return customFields?.map(
        customField => {
          const customValue = customValues?.find(cv => customField.id === cv.fieldId)
          return (
            <ComposableField
              key={customField.id}
              field={customField}
              defaultChildValue={customValue?.values}
              disabled={!!cond.existingContentShareORM}
            />
          )
        },
      )
    },
    [cond.shareVariant],
  )

  const EmailTemplateRow: React.FC<EmailTemplateRowProps> = ({ emailTemplateORM }) => {
    const isRadioChecked = (emailTemplateId: string) => {
      return cond.selectedEmailTemplate?.model.id === emailTemplateId
    }

    const handleRadioSelect = (emailTemplateORM: EmailTemplateORM) => {
      service.send({
        type: 'UPDATE_EMAIL_TEMPLATE',
        emailTemplateORM,
      })
    }

    return (
      <RadioItem.Kitten
        checked={isRadioChecked(emailTemplateORM.model.id)}
        onChange={() => handleRadioSelect(emailTemplateORM)}
        style={styles.radioButton}
      >
        {() => (
          <DNABox
            appearance="row"
            spacing="between"
            alignY="center"
            fill
            childFill={0}
          >
            <DNABox appearance="col" fill spacing="sm">
              {/* TITLE */}
              <DNAText numberOfLines={2} style={{ marginLeft: 16 }} b1>
                {emailTemplateORM.model.title}
              </DNAText>

              {/* CUSTOM FILTER CHIPS */}
              <DNABox appearance="row" fill spacing="sm" style={{ marginLeft: 16 }} alignY="center">
                {
                  Object
                    .values(emailTemplateORM.meta.customFilterValues)
                    .flat()
                    ?.map(({ displayValues }) => (
                      displayValues.map((value, idx) => (
                        <DNAChip key={value + idx}>
                          {value}
                        </DNAChip>
                      ))
                    ))
                }
              </DNABox>
            </DNABox>
          </DNABox>
        )}
      </RadioItem.Kitten>
    )
  }

  const EmailTemplatesSection: React.FC = () => {
    const emailTemplates = useEmailTemplateListForMSL()
    return (
      <DNABox style={styles.commonBorder}>
        <RadioGroup.Kitten style={{ width: 700 }}>
          {emailTemplates?.map((et, idx) => {
            return (
              <DNABox
                testID="email-template-share-row"
                style={[idx !== emailTemplates.length - 1 && styles.bottomBorder]}
                fill
                key={et.model.id}
              >
                <EmailTemplateRow key={et.model.id} emailTemplateORM={et}/>
              </DNABox>
            )
          })}
        </RadioGroup.Kitten>
      </DNABox>
    )
  }

  const ShareFooter: React.FC = () => {
    const { rhForm, service } = useShareMachine()
    const emailTemplates = useEmailTemplateListForMSL()
    const cond = useSelector(
      service,
      (state) => composite(
        state,
        shareMachine.selectedEmailTemplate,
        shareMachine.existingContentShareORM,
        shareMachine.isCopyingFile,
        shareMachine.isOpeningEmail,
        shareMachine.isCopyingEmail,
        shareMachine.copyFileSuccess,
        shareMachine.copyFileError,
        shareMachine.copyFileNameButtonStatus,
        shareMachine.copyEmailButtonStatus,
        shareMachine.openEmailButtonStatus,
        shareMachine.shareVariant,
        shareMachine.noEntitySelectedToShare,
        shareMachine.notificationScope,
      ),
    )

    const handleResetForm = () => {
      rhForm?.reset(undefined, { keepDirty: false })
      service.send({ type: 'RESET_FORM' })
    }

    const handlePress = (stateMachineEvent: string) => {
      validateAndSubmit && validateAndSubmit(stateMachineEvent)
    }

    const handleNotificationScopeChange = (value: boolean) => {
      const notificationScope = value ? ShareNotificationScope.EMAIL : ShareNotificationScope.NONE
      service.send({
        type: 'UPDATE_NOTIFICATION_SCOPE',
        notificationScope,
      })
    }

    // when this flag is set to true from tenant config we hide the button
    const hideCopyTitleAndLink = useFeatureFlags('hideCopyTitleAndLink');
    const isCopyBtnDisabled =
      (!rhForm?.formState.isDirty && !cond.existingContentShareORM) || cond.noEntitySelectedToShare
    const isEmailBtnDisabled = isCopyBtnDisabled || !cond.selectedEmailTemplate || cond.noEntitySelectedToShare

    const checked = [
      cond.notificationScope,
      cond.existingContentShareORM?.model.notificationScope,
    ].includes(ShareNotificationScope.EMAIL)

    return (
      <DNABox fill appearance="col" alignY="end" style={{ marginBottom: 20, marginTop: 4 }} >

        <DNABox spacing="sm" alignX="end" style={{ marginBottom: 12 }}>
          <DNACheckbox
            disabled={!rhForm?.formState.isDirty}
            checked={checked}
            onChange={(value) => handleNotificationScopeChange(value)}
          />
          <DNAText>Notify me when document is accessed</DNAText>
        </DNABox>

        <DNADivider style={{ marginBottom: 12 }} />

        <DNABox spacing="between">
          <DNABox>
            <DNAButton
              testID="shared-modal-reset-btn"
              appearance="outline"
              status="tertiary"
              onPress={() => handleResetForm()}
              disabled={!rhForm?.formState.isDirty}
            >
              Reset
            </DNAButton>
          </DNABox>
          <DNABox spacing="xs">
            <Iffy is={!hideCopyTitleAndLink}>
              <DNAButton
                testID="shared-modal-copy-link-btn"
                appearance={CopyLinkButtonProps[cond.copyFileNameButtonStatus].appearance}
                onPress={() => handlePress('COPY_LINK')}
                status={CopyLinkButtonProps[cond.copyFileNameButtonStatus].status}
                disabled={isCopyBtnDisabled}
                iconLeft={CopyLinkButtonProps[cond.copyFileNameButtonStatus].icon}
              >
                {CopyLinkButtonProps[cond.copyFileNameButtonStatus][cond.shareVariant].label}
              </DNAButton>
            </Iffy>
            <Iffy is={emailTemplates.length}>
              <DNAButton
                testID="shared-modal-copy-email-btn"
                appearance={CopyEmailButtonProps[cond.copyEmailButtonStatus].appearance}
                status={CopyEmailButtonProps[cond.copyEmailButtonStatus].status}
                onPress={() => handlePress('COPY_EMAIL')}
                disabled={isEmailBtnDisabled}
                iconLeft={CopyEmailButtonProps[cond.copyEmailButtonStatus].icon}
              >
                {CopyEmailButtonProps[cond.copyEmailButtonStatus].label}
              </DNAButton>
              <DNAButton
                testID="shared-modal-open-email-btn"
                appearance={OpenEmailButtonProps[cond.openEmailButtonStatus].appearance}
                status={OpenEmailButtonProps[cond.openEmailButtonStatus].status}
                onPress={() => handlePress('OPEN_EMAIL')}
                disabled={isEmailBtnDisabled}
                iconLeft={OpenEmailButtonProps[cond.openEmailButtonStatus].icon}
              >
                {OpenEmailButtonProps[cond.openEmailButtonStatus].label}
              </DNAButton>
            </Iffy>
          </DNABox>
        </DNABox>
      </DNABox>
    )
  }

  return (
    <DNAModal style={styles.modal}>
      <DNAModal.Header
        onClose={closeModal}
        style={{ padding: 16 }}
      >
        <DNABox>
          <DNAText testID="shared-modal-title" h5>
            Share {cond.shareVariant === ShareVariantOptions.DOC_VER_SHARE ? 'File' : 'Hub'}
          </DNAText>
        </DNABox>
      </DNAModal.Header>

      <DNAModal.Body style={styles.modalBody}>
        <DNABox
          as={ScrollView}
          appearance="col"
          spacing="xs"
          fill
          style={styles.modalContainer}
        >
          <DNABox
            fill
            appearance="col"
            spacing="md"
            testID="custom-field"
            style={[styles.sectionBody, styles.commonBorder]}
          >
            <DNAText h5>Step 1. Details</DNAText>
            {memoizedCustomFields}
          </DNABox>
          <Iffy is={cond.shareVariant === ShareVariantOptions.DOC_VER_SHARE}>
            <DNABox
              appearance="col"
              spacing="xs"
              testID="associated-files"
              style={[styles.sectionBody, styles.commonBorder, isRequiredFieldDirty && styles.disabled]}
            >
              <DNAText h5>Step 2. Select Files to Share</DNAText>
              <AssociatedFilesSection />
            </DNABox>
          </Iffy>
          <Iffy is={emailTemplates.length}>
            <DNABox
              fill
              appearance="col"
              spacing="md"
              testID="email-templates"
              style={[
                styles.sectionBody,
                styles.commonBorder,
                isEmailTemplateSectionDisabled && styles.disabled,
                { marginBottom: 24 },
              ]}
            >
              <DNAText h5>
                Step {cond.shareVariant === ShareVariantOptions.DOC_VER_SHARE ? '3.' : '2.'} Select Email
              </DNAText>
              <EmailTemplatesSection />
            </DNABox>
          </Iffy>
        </DNABox>
      </DNAModal.Body>

      <DNAModal.Footer>
        <ShareFooter />
      </DNAModal.Footer>
    </DNAModal>
  )
}
export const ContentShareModal: DNAModalVariant<{
  variant: ShareVariantType
  entityShareIds: string[]
  existingContentShareORM?: ContentShareORM
  hubTitle?: string,
  meetingId?: string
}> = props => {
  const {
    variant,
    closeModal,
    entityShareIds,
    existingContentShareORM,
    hubTitle,
    meetingId,
  } = props

  const usages = { internalUsages: [shareVariantConfig[variant].customFieldUsage] }

  const customFieldsConfig = useMemo(
    () => ({ usages }),
    [variant],
  )

  // TODO since we are getting the customFields once should we pass it as props to the modal?
  const customFields = useTenantCustomFields(customFieldsConfig)
  const existingCustomValues = existingContentShareORM?.model.customValues
  const customFieldValuesMap = getMappedCustomValues(usages, existingCustomValues, customFields)

  return (
    <ComposableForm customFields={customFields} values={customFieldValuesMap}>
      <ShareMachineProvider.Unmanaged
        variant={variant}
        entityShareIds={entityShareIds}
        closeModal={closeModal}
        existingContentShareORM={existingContentShareORM}
        hubTitle={hubTitle}
        meetingId={meetingId}
      >
        <ContentShareModalBody />
      </ShareMachineProvider.Unmanaged>
    </ComposableForm>
  )
}
