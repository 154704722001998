import { useEffect, useRef } from 'react';
import { useDispatch } from 'src/state/redux';
import { Dispatch } from 'redux';
import { BroadcastChannel } from 'broadcast-channel';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { meetingActions } from 'src/state/redux/slice/meeting';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { useIsExternalPlayer } from 'src/screens/Loading';
import { PopoutContentBCPayloads, PopoutContentBCTypes } from './useMeetingsPopoutContentState';
import { MeetingORM } from 'src/types/orms';

interface HookReferences {
  isOnline: boolean,
  isExternalPlayer: boolean,
  isSwitchingMode: boolean,
  meetingORM?: MeetingORM,
}

/**
 * This hook is designed to keep the Presenter Window updated with meeting changes from the Popout Window
 * while in Presenter Mode and offline. Since DataStore updates within the Popout Window don’t trigger the
 * subscription event in the Presenter Window, this hook sends a message to the Presenter Window whenever
 * there’s a meeting update in the Popout Window.
 * @hook
 * @param meetingId: string | undefined: Used to get the MeetingORM
 */
const useHandleOfflineMeeting = (meetingId?: string): void => {
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>();
  const { isOnline } = useAppSettings();
  const meetingORM = useMeeting(meetingId || '');
  const isExternalPlayer = useIsExternalPlayer();
  const dispatch = useDispatch();
  const isSwitchingMode = useRef<boolean>(false);
  const refs = useRef<HookReferences>({
    isOnline,
    isExternalPlayer,
    isSwitchingMode: isSwitchingMode.current,
    meetingORM,
  });

  function setIsSwitchingMode(isSwitchingMode: boolean): void {
    refs.current.isSwitchingMode = !!isSwitchingMode;
  }

  useEffect(() => {
    refs.current = {
      isOnline,
      isExternalPlayer,
      isSwitchingMode: isSwitchingMode.current,
      meetingORM,
    };
  }, [isOnline, isExternalPlayer, isSwitchingMode, meetingORM]);

  useEffect(() => {
    setIsSwitchingMode(false);
  }, [meetingORM?.model.type]);

  // SENDS A MESSAGE OVER THE CHANNEL UPON A MEETING UPDATE //
  useEffect(() => {
    const shouldSendMeetingUpdateMessage = !isOnline && isExternalPlayer && !!meetingORM;

    if (shouldSendMeetingUpdateMessage) {
      broadcastChannel.current?.postMessage({
        type: PopoutContentBCTypes.meetingUpdate,
        meeting: meetingORM.model,
        meetingId: meetingORM?.model.id,
      });
    }
  }, [meetingORM]);

  // INITIALIZES BROADCAST CHANNEL //
  useEffect(() => {
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('MEETING_CONTENT');
    broadcastChannel.current.onmessage =
      (payload: PopoutContentBCPayloads) =>
        broadcastMessageHandler(payload, refs.current, dispatch, setIsSwitchingMode);

    return () => {
      broadcastChannel.current?.close();
      broadcastChannel.current = undefined;
    };
  }, []);
};

/**
 * Handles the broadcast channel message to update the meeting's record.
 * @function
 * @param payload: PopoutContentBCPayloads: broadcast channel message
 * @param refs: HookReferences: required values to handle the message
 * @param dispatch: Dispatch
 * @param setIsSwitchingMode: (isSwitchingMode: boolean) => void: updates the flag to avoid handling more messages
 */
async function broadcastMessageHandler(
  payload: PopoutContentBCPayloads,
  refs: HookReferences,
  dispatch: Dispatch,
  setIsSwitchingMode: (isSwitchingMode: boolean) => void): Promise<void> {
  const { isOnline, isExternalPlayer, isSwitchingMode, meetingORM } = refs;
  const shouldHandleChannelMessage = !isOnline && !isExternalPlayer &&
    meetingORM?.model.type === MeetingType.VIRTUAL;

  if (shouldHandleChannelMessage) {
    switch (payload.type) {
      case PopoutContentBCTypes.meetingUpdate:
        !isSwitchingMode &&
        dispatch(meetingActions.updateMeeting(meetingORM.model, payload.meeting));
        break;
      case PopoutContentBCTypes.toggleMeetingType:
        setIsSwitchingMode(true);
        break;
    }
  }
}

export default useHandleOfflineMeeting;
