import { PayloadAction } from '@reduxjs/toolkit';
import { FolderItemORM, FolderORM } from 'src/types/types';
import createSlice from 'src/state/redux/util/createImmerlessSlice'

export interface PresentationBuilderSliceType {
  visible?: boolean
  targetFolder?: FolderORM
  customDeckId?: string
  folderItemORM?: FolderItemORM
  variant?: PRESENTATION_BUILDER_VARIANT,
  context?: string,
  editorType?: EDITOR_TYPE,
}

export enum PRESENTATION_BUILDER_VARIANT {
  EDIT = 'EDIT',
  READ = 'READ',
}

export enum EDITOR_TYPE {
  OWNER = 'OWNER',
  COLLABORATOR = 'COLLABORATOR',
}

const initialState: PresentationBuilderSliceType = {
  visible: false,
  targetFolder: undefined,
  customDeckId: undefined,
  folderItemORM: undefined,
  variant: undefined,
  context: undefined,
  editorType: undefined,
}

const presentationBuilderSlice = createSlice({
  name: 'presentationBuilderState',
  initialState: initialState,
  reducers: {
    /**
     * This reducer will either toggle the current visibility of the builder or,
     * if included, set the visibility to the provided state
     * @param state
     * @param action
     * @returns global presentation builder state values
     */
    closePresentationBuilder: (state) => {
      return {
        ...state,
        visible: false,
      };
    },
    openPresentationBuilderEdit: (state, action: PayloadAction<PresentationBuilderSliceType>) => {
      const { payload } = action;
      const newState = { ...state }

      newState.visible = true;
      newState.targetFolder = payload.targetFolder;
      newState.customDeckId = payload.customDeckId;
      newState.folderItemORM = payload.folderItemORM;
      newState.editorType = payload.editorType;
      newState.variant = payload.variant;

      analytics?.track('CUSTOM_OPEN_BUILDER', {
        action: 'OPEN_BUILDER',
        category: 'CUSTOM',
        context: payload.context ?? 'EDIT',
        customDeckId: payload.customDeckId,
        editorType: payload.editorType,
      })

      return newState;
    },
    openPresentationBuilderNew: (state, action: PayloadAction<PresentationBuilderSliceType>) => {
      const { payload } = action;
      const newState = { ...state }
      newState.visible = true;
      newState.targetFolder = payload.targetFolder;
      newState.editorType = payload.editorType;
      newState.customDeckId = undefined;
      newState.folderItemORM = undefined;
      newState.variant = undefined;

      analytics?.track('CUSTOM_OPEN_BUILDER', {
        action: 'OPEN_BUILDER',
        category: 'CUSTOM',
        context: 'CREATE',
        editorType: payload.editorType,
      })

      return newState;
    },
  },
});

export default presentationBuilderSlice;
export const presentationBuilderActions = {
  ...presentationBuilderSlice.actions,
}
