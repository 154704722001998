import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react';
import { v4 as uuid } from 'uuid';
import deepEqual from 'fast-deep-equal';
import {
  DndContext,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  rectIntersection,
  closestCenter,
  closestCorners,
  pointerWithin,
  MeasuringStrategy,
  DragStartEvent,
  DragOverEvent,
  Active,
} from '@dnd-kit/core';

export { DragOverlay } from '@dnd-kit/core';
export type { Active } from '@dnd-kit/core';
export { snapCenterToCursor } from '@dnd-kit/modifiers';
export {
  DroppableContainer,
  SortableItem,
} from 'src/components/DnD/DnDWrapper';

export type TargetItem<T extends {} = {}> = { id: string, itemId: string } & T
export type GroupedTargetItems<T extends {} = {}> = Record<string, TargetItem<T>[]>
export type DndFinalDestination = {
  group: string,
  id: string,
  itemId: string,
}

type ISingleItemDndContext = {
  activeId: string | null,
  setActiveId: React.Dispatch<React.SetStateAction<string | null>>,
  activeItemId: string | null,
  setActiveItemId: React.Dispatch<React.SetStateAction<string | null>>,
  poolItems: TargetItem[],
  setPoolItems: React.Dispatch<React.SetStateAction<TargetItem[]>>,
  groupedTargetItems: GroupedTargetItems,
  setGroupedTargetItems: React.Dispatch<React.SetStateAction<GroupedTargetItems>>,
  activeContainerOrigin: React.MutableRefObject<string | null>,
  onDragEndDestination: React.MutableRefObject<DndFinalDestination | null>,
}

enum CollisionDetectionsEnum {
  rectIntersection = 'rectIntersection',
  closestCenter = 'closestCenter',
  closestCorners = 'closestCorners',
  pointerWithin = 'pointerWithin',
}

type CollisionDetections = keyof typeof CollisionDetectionsEnum

const collisionDetections = {
  rectIntersection,
  closestCenter,
  closestCorners,
  pointerWithin,
}

/**
 * CONTEXT
 */
const SingleItemDndContext = createContext<ISingleItemDndContext>({} as any)
export const useSingleItemDnd = () => {
  const context = useContext(SingleItemDndContext)
  if (!context) {
    throw new Error('useSingleItemDnd must be used within the SingleItemDndContext Provider')
  }
  return context
}

/**
 * STATICS
 */
export const measureStrategy = { droppable: { strategy: MeasuringStrategy.Always } }
export const pointerOptions = { activationConstraint: { distance: 15 } }
export const touchOptions = { activationConstraint: { delay: 50, tolerance: 0 } }

export const POOL_CONTAINER_ID = 'POOL'

const generatePoolIds = (poolItemsIn: string[]): TargetItem[] => poolItemsIn.map(itemId => ({ id: uuid(), itemId }))
const randomizePoolIds = (poolItems: TargetItem[]) => poolItems.map(item => ({ ...item, id: uuid() }))

const generateTargetItems = (
  targetItems: Record<string, string[]>,
  existingItems?: GroupedTargetItems,
): GroupedTargetItems => Object
  .entries(targetItems)
  .reduce(
    (acc, [groupName, groupItems]) => {
      // Attempt to re-use same UUIDs to avoid re-rerenders
      const existingMap = Object
        .entries(existingItems ?? {})
        .reduce<Record<string, Record<string, string>>>(
          (accB, [groupId, existingGroupItems]) => {
            return {
              ...accB,
              [groupId]: existingGroupItems.reduce<Record<string, string>>(
                (accC, existingGroupitem) => {
                  return { ...accC, [existingGroupitem.itemId]: existingGroupitem.id }
                },
                { },
              ),
            }
          },
          { },
        )

      return {
        ...acc,
        [groupName]: groupItems.map(
          itemId => ({
            id: existingMap?.[groupName]?.[itemId] ?? uuid(),
            itemId,
          }),
        ),
      }
    },
    { },
  )

/**
 * `DNASingleItemDnd` enables drag-and-drop functionality for a single item, ensuring that the droppable container can hold only one item at a time.
 * While dragging an item over the container, an animation will simulate the replacement of the current item.
 * If the item is dragged away, the container will revert to the previously stored item.
 * Once the item is dropped, it will replace the existing item in the droppable container.
 * @param {*} poolItems array of id from the pool
 * @param {*} targetItems object with the key of droppable container id and value of droppable container item id
 * @param {*} onDragStart callback function on drag start
 * @param {*} onDragEndChanged callback function on drag end changed
 * @param {*} collisionDetection [collisionDetection](https://docs.dndkit.com/api-documentation/context-provider/collision-detection-algorithms) is an optional parameter specifying the collision detection strategy to be used for the drag and drop interactions.
 * @see Checkout [dndkit Storybook](https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/core-draggable-hooks-usedraggable--basic-setup) for more options.
*/
export const DNASingleItemDnd: React.FC<PropsWithChildren<{
  poolItems: string[],
  targetItems: Record<string, string[]>,
  onDragStart?: (active: Active) => void,
  onDragEndChanged?: (targetItems: GroupedTargetItems, dndFinalDestination: DndFinalDestination) => void,
  collisionDetection?: CollisionDetections,
  disableRandomizePoolId?: boolean,
}>> = (props) => {
  const {
    poolItems: poolItemsIn,
    targetItems,
    onDragStart: onDragStartIn,
    onDragEndChanged,
    collisionDetection,
    disableRandomizePoolId,
    children,
  } = props

  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const [poolItems, setPoolItems] = useState<TargetItem[]>(() => generatePoolIds(poolItemsIn))
  const [groupedTargetItems, setGroupedTargetItems] = useState<GroupedTargetItems>(
    () => generateTargetItems(targetItems),
  )

  const sensors = useSensors(
    useSensor(MouseSensor, pointerOptions),
    useSensor(TouchSensor, touchOptions),
  );

  useEffect(
    () => {
      setPoolItems(generatePoolIds(poolItemsIn))
    },
    [poolItemsIn],
  )

  const activeContainerOrigin = useRef<string | null>(null)
  const onDragEndDestination = useRef<DndFinalDestination | null>(null)
  const onDragEndChangedDestination = useRef<DndFinalDestination | null>(null)
  const groupedTargetItemsRef = useRef<GroupedTargetItems<{}> | null>(null)
  const didMount = useRef<boolean>(false)
  const groupedTargetChangedOrigin = useRef<'props' | 'onDragOver' | 'onDragEnd' | undefined>()

  const groupedItemsIdMap = useMemo<Record<string, Record<string, boolean>>>(
    () => {
      return Object
        .entries(groupedTargetItems)
        .reduce(
          (acc, [groupId, groupItems]) => ({
            ...acc,
            [groupId]: groupItems
              .reduce(
                (accc, item) => ({ ...accc, [item.itemId]: true }),
                {},
              ),
          }),
          {},
        )
    },
    [groupedTargetItems],
  )

  // Apply any changes from outside props to DNASingleItemDnd
  useEffect(
    () => {
      if (!didMount.current) {
        didMount.current = true
        return
      }

      setGroupedTargetItems(p => {
        const destructedTargetItems = Object
          .entries(p)
          .reduce<Record<string, string[]>>(
            (acc, [groupName, groupItems]) => ({
              ...acc,
              [groupName]: groupItems.map(item => item.itemId),
            }),
            {},
          )

        const isEquivalent = deepEqual(destructedTargetItems, targetItems)

        if (isEquivalent) {
          return p
        } else {
          groupedTargetChangedOrigin.current = 'props'
          return generateTargetItems(targetItems, p)
        }
      })
    },
    [targetItems],
  )

  // [NOTE] - implicitly call `onDragEndChanged`
  //        - original implementation used to compare items to a cloned array
  //          but that may not have been necessary.
  //        - we can also consider just calling `onDragEndChanged` directly in the
  //          relevant `setGroupedTargeItems` in `onDragOver`
  useEffect(() => {
    if (
      onDragEndChanged &&
      !activeContainerOrigin.current &&
      groupedTargetChangedOrigin.current === 'onDragEnd' &&
      onDragEndChangedDestination.current
    ) {
      onDragEndChanged(groupedTargetItems, onDragEndChangedDestination.current)
    }
  }, [groupedTargetItems, onDragEndChanged])

  const onDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      if (!active.data.current) return;
      activeContainerOrigin.current = active.data.current?.containerId
      groupedTargetItemsRef.current = groupedTargetItems
      setActiveId(active.id.toString())
      setActiveItemId(active.data.current?.itemId)
      onDragStartIn?.(active)
    },
    [groupedTargetItems, onDragStartIn],
  )

  const onDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const overId = over?.id;

      if (!groupedTargetItemsRef.current) return;

      // The sorting preset handles its own onDragOver, onDragEnd will take care of committing the changes
      // NO-OP if not starting from pool items
      if (activeContainerOrigin.current && activeContainerOrigin.current !== POOL_CONTAINER_ID) return;

      const activeContainer = active.data.current?.containerId
      const overContainer = over?.data.current?.containerId
      const isOverPool = overContainer === POOL_CONTAINER_ID
      const isOverItself = activeContainer === overContainer
      const isOverEmptyGroup = over?.data.current?.type === 'container'
      const overGroupItemContainerId = over?.data.current?.type !== 'container' && over?.data.current?.containerId

      // NO-OP if over itself
      if (isOverItself) {
        onDragEndDestination.current = null;
        return;
      }

      // When over pool or NOT over any container
      // if previously has added to droppable container, remove them
      // else NO-OP
      if (isOverPool || !overId) {
        onDragEndDestination.current && setGroupedTargetItems(p => {
          // During temporary item insertion, remove the item from any other groups
          const otherFiltered = (
            onDragEndDestination.current &&
            groupedTargetItemsRef.current
          )
            ? {
              [onDragEndDestination.current.group]: groupedTargetItemsRef.current[onDragEndDestination.current.group],
            }
            : {}

          const res = {
            ...p,
            ...otherFiltered,
          }
          return res
        })
        onDragEndDestination.current = null;
        return;
      }

      // POOL -> GROUP (EMPTY SPACE)
      if (isOverEmptyGroup) {
        const overGroupId = overId.toString()
        if (!activeItemId) return;

        // Track the active group
        onDragEndDestination.current = {
          group: overGroupId,
          id: active.id.toString(),
          itemId: activeItemId,
        }
        groupedTargetChangedOrigin.current = 'onDragOver';
        return;
      }

      // POOL -> GROUP (OVER ITEM)
      if (overGroupItemContainerId) {
        if (!activeItemId) return;

        // Track the active group
        onDragEndDestination.current = {
          group: overGroupItemContainerId,
          id: active.id.toString(),
          itemId: activeItemId,
        }
        groupedTargetChangedOrigin.current = 'onDragOver';
      }
    },
    [setGroupedTargetItems, groupedTargetItems, groupedItemsIdMap, activeItemId],
  )

  const onDragEnd = useCallback(() => {
    const isFromPool = activeContainerOrigin.current === POOL_CONTAINER_ID

    // POOL -> GROUP
    if (isFromPool && onDragEndDestination.current) {
      const { id, itemId, group } = onDragEndDestination.current
      onDragEndChangedDestination.current = { id, itemId, group }

      setGroupedTargetItems(p => {
        groupedTargetChangedOrigin.current = 'onDragEnd'

        return {
          ...p,
          [group]: [{ id, itemId }],
        }
      })
    }

    // [NOTE] - Need to re-randomize pool ids whenever we finishing dragging
    //          so that the next time we add an item from pool, all pool items have a new UUID
    //        - This allows adding the same item from the pool multiple times without id conflicts
    //        - This can still be optimized by only re-randomizing pool items that were added to target
    //          but it's not a big deal at the moment since the consuming components should use them itemId
    //          as the stable key for mapping items anyways
    setPoolItems(p => {
      const res = onDragEndDestination.current && !disableRandomizePoolId
        ? randomizePoolIds(p)
        : p

      onDragEndDestination.current = null
      return res
    })

    activeContainerOrigin.current = null
    setActiveId(null);
    setActiveItemId(null);
  }, [poolItems, groupedTargetItems, setPoolItems])

  const value = useMemo<ISingleItemDndContext>(
    () => ({
      activeId,
      setActiveId,
      activeItemId,
      setActiveItemId,
      poolItems,
      setPoolItems,
      groupedTargetItems,
      setGroupedTargetItems,
      activeContainerOrigin,
      onDragEndDestination,
    }),
    [activeId, setActiveId, activeItemId, setActiveItemId, poolItems, setPoolItems, groupedTargetItems],
  )

  return (
    <DndContext
      sensors={sensors}
      measuring={measureStrategy}
      collisionDetection={collisionDetection ? collisionDetections[collisionDetection] : undefined}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
    >
      <SingleItemDndContext.Provider value={value}>
        {children}
      </SingleItemDndContext.Provider>
    </DndContext>
  )
}

export default DNASingleItemDnd
