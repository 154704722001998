import React, { useCallback, useMemo } from 'react';
import { ScrollView } from 'react-native';
import { DNABox } from '@alucio/lux-ui';
import DNALoader from 'src/components/DNA/Loader/DNALoader';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import MatchSlidesStateProvider, {
  useMatchSlidesState,
} from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import DNASingleItemDnd, { DndFinalDestination, GroupedTargetItems } from 'src/components/DnD/DNASingleItemDnd';
import SlidesGroupPool from './SlidesGroupPool';
import SlideGroupTargets from './SlideGroupTargets/SlideGroupTargets';
import SlidesGroupOverlay from './SlidesGroupOverlay';
import MatchSlidesReadonly from './MatchSlidesReadonly';
import { calculateDisplaySlides } from 'src/state/machines/publisherVersioning/util';

const MatchSlidesEditMode: React.FC = () => {
  const {
    service,
    mappedValues,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
    onMatch,
  } = useMatchSlidesState();

  /* Array of pageId from the lastest published docVer */
  const poolItems = useMemo(() => latestPublishedDocumentVersionORM.relations.pages.map(page => page.model.pageId),
    [latestPublishedDocumentVersionORM]);

  /* Object with keys as current draft's pageIds and value as an array contains the selected matching pageId */
  const targetItems = useMemo(() => {
    return calculateDisplaySlides(currentDocumentVersionORM, mappedValues)
  }, [currentDocumentVersionORM, mappedValues]);

  const onDragEndChanged = useCallback((targetItems: GroupedTargetItems, dndFinalDestination: DndFinalDestination) => {
    onMatch(targetItems, dndFinalDestination.group, dndFinalDestination.itemId)
  }, [service])

  return (
    <DNASingleItemDnd
      poolItems={poolItems}
      targetItems={targetItems}
      collisionDetection={'pointerWithin'}
      onDragEndChanged={onDragEndChanged}
    >
      <DNABox as={ScrollView} fill >
        {/* left side bar */}
        <SlidesGroupPool />
        {/* right side */}
        <SlideGroupTargets />
      </DNABox>
      <SlidesGroupOverlay />
    </DNASingleItemDnd>
  )
}

const MatchSlidesReadOnlyMode: React.FC = () => {
  return (
    <DNABox fill>
      <MatchSlidesReadonly />
    </DNABox>
  )
}

const MatchSlidesWrapper: React.FC = () => {
  const {
    service,
    latestPublishedDocumentVersionORM,
    latestDocumentVersionContentPageData,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.isProcessingNewVersion,
      publisherVersioningSelector.isPublishing,
    ),
  )
  const showLoader = !latestPublishedDocumentVersionORM ||
    cond.isProcessingNewVersion ||
    cond.isPublishing ||
    !latestDocumentVersionContentPageData?.length

  if (showLoader) return <DNALoader transparentBackground/>;
  return (
    <MatchSlidesStateProvider>
      {cond.isInDraftingState ? <MatchSlidesEditMode /> : <MatchSlidesReadOnlyMode />}
    </MatchSlidesStateProvider>
  );
};

export default MatchSlidesWrapper;
